import React from 'react';
const toAbsoluteUrl = (pathname) => process.env.PUBLIC_URL + pathname;

function PaidStamp() {
    return (
        <div
        className="bg-image-center"
        style={{
          backgroundImage: `url(${toAbsoluteUrl(
            "../assets/img/logo/paid13.png"
          )})`,
          height: "200px",
          width: "500px",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          opacity: "0.4",
          position: "absolute",
          zIndex: 2,
          top:"45%",
          right:"200px"
        }}
      ></div>
    );
}

export default PaidStamp;