import { FETCH_CATEGORY_TREE } from "../constant_types";

const initState = {
  categoryTree: [],
  categoryList: [],
};

const categoryReducer = (state = initState, action) => {
  if (action.type === FETCH_CATEGORY_TREE) {
    return {
      ...action.payload,
    };
  }

  return state;
};

export default categoryReducer;
