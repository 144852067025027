import {
  GET_ALL_STATES_SUCCESS,
  GET_ALL_BILLING_STATES,
  GET_ALL_BILLING_CITIES,
  GET_ALL_CITIES_SUCCESS,
  SET_LOADING,
  GET_ALL_CUSTOM_PAGES,
  GET_SINGLE_CUSTOM_PAGE,
  EXPORT_PDF_CSV,
  EXPORT_PDF_CSV_LOADING,
  EXPORT_PDF_CSV_STOPPED,
  GET_ALL_COUNTIES_SUCCESS
} from "../constant_types";

const initialState = {
  billingCountries: [],
  billingCities: [],
  billingStates: [],
  states: [],
  cities: [],
  counties:[],
  loading: false,
  customPages:[],
  singlePage:null,
  export_loading:false
};

export const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_STATES_SUCCESS:
      return {
        ...state,
        states: action.payload,
      };
   case GET_ALL_COUNTIES_SUCCESS:
    return{
      ...state,
      counties: action.payload,
    }
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case GET_ALL_BILLING_STATES:
      return {
        ...state,
        billingStates: action.payload,
      };

    case GET_ALL_CITIES_SUCCESS:
      return {
        ...state,
        cities: action.payload,
      };

    case GET_ALL_BILLING_CITIES:
      return {
        ...state,
        billingCities: action.payload,
      };
      case GET_ALL_CUSTOM_PAGES:
        return{
          ...state, 
          customPages:action.payload
        };
        case GET_SINGLE_CUSTOM_PAGE:
          return {
            ...state, 
            singlePage:action.payload
          }
          case EXPORT_PDF_CSV:
          return {
            ...state, 
            exportPdf:action.payload
          }
          case EXPORT_PDF_CSV_LOADING:
          return {
            ...state, 
            export_loading:true
          }
          case EXPORT_PDF_CSV_STOPPED:
          return {
            ...state, 
            export_loading:false
          }
          case EXPORT_PDF_CSV:
            return {
              ...state, 
              export_loading:false,
              ExportStatus :action.payload.status,
              ExportUrl :action.payload.url,
            }
    default:
      return state;
  }
};
