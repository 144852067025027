import axios from "axios";
import { ECOM_TEMPLATE_URL } from "../../constants/config/config.local";

export const FETCH_ECOM_TEMPLATE_SUCCESS = "FETCH_ECOM_TEMPLATE_SUCCESS";

const fetchEcomTemplateSuccess = (template) => ({
  type: FETCH_ECOM_TEMPLATE_SUCCESS,
  payload: template,
});

// update user
export const fetchEcomTemplate = () => {
  return (dispatch) => {
    axios
      .get(`${ECOM_TEMPLATE_URL}`)
      .then((res) => {
        const { entity } = res.data;
        dispatch(fetchEcomTemplateSuccess(entity));
      })
      .catch((err) => {});
  };
};
