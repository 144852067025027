
import React from "react";
import { Field, ErrorMessage } from "formik";
import { getPath, TextError } from "../../pages/constant/utils";

const getFieldCSSClasses = (touched, errors) => {
    const classes = ["form-control"];
    if (touched && errors) {
        classes.push("is-invalid");
    }
    if (touched && !errors) {
        // classes.push("is-valid");
    }
    return classes.join(" ");
};


export function YesNoSelect({
    field,
    label,
    form: { touched, errors },
    ...props
}) {
    return (
        <>
            {label && <label>{label}</label>}
            <div style={{display:"flex" , alignItems:"center", padding:"18px 24px"}}>
                <div style={{marginRight:"10px", display:"flex",alignItems:"center"}}>
                    
                    <label for="yesOption" className="checkbox-container">Yes
                    <input style={{width:"24px",marginRight:"8px",cursor:"pointer"}}
                        id="yesOption"
                        type="checkbox"
                        name={field.name}
                        checked={field.value === "Y"}
                        onChange={() => field.onChange({ target: { name: field.name, value: "Y" } })}
                    />
                    <span class="checkmark"></span>
                    </label>
                </div>
                <div style={{display:"flex",alignItems:"center"}}>
                   
                    <label for="noOption" className="checkbox-container">No
                    <input
                        style={{width:"24px",marginRight:"8px",cursor:"pointer"}}
                        id="noOption"
                        type="checkbox"
                        name={field.name}
                        checked={field.value === "N"}
                        onChange={() => field.onChange({ target: { name: field.name, value: "N" } })}
                    />
                    <span class="checkmark"></span>

                    </label>
                </div>
            </div>

            {<ErrorMessage name={field.name} component={TextError} />}
        </>
    );
}
