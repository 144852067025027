import axios from "axios";
import { SETTINGS_URL } from "../../constants/config";
export const FETCH_SETTINGS_SUCCESS = "FETCH_SETTINGS_SUCCESS";

const fetchSettingsSuccess = (settings) => ({
  type: FETCH_SETTINGS_SUCCESS,
  payload: settings,
});

// fetch settings
export const fetchSettings = (params) => {
  return (dispatch) => {
    axios
      .get(`${SETTINGS_URL}/v2/list`, { params })
      .then((res) => {
        dispatch(fetchSettingsSuccess(res.data.entity));
      })
      .catch((err) => {});
  };
};
