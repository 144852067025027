import React, { useEffect } from "react";
import { Modal, Button, Row, Col } from "antd";
import { connect } from "react-redux";
import { isEmpty } from "lodash";

import { store } from "../..";
import { fetchOrderById } from "../../redux/actions/checkoutActions";
import { formatDateTime } from "../../pages/constant/utils";
const OrderDetailModal = ({
  id,
  show,
  handleModalClose,
  userDetail,
  orderDetail,
  currency,
}) => {
  useEffect(() => {
    if (id) {
      store.dispatch(fetchOrderById(id, userDetail));
    }
  }, [id]);
  return (
    <>
      <Modal show={show} size="lg" onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!isEmpty(orderDetail) && (
            <>
              <Row>
                <>
                  {orderDetail && orderDetail.sale_order_details.length > 0 ? (
                    <Col lg={6} md={6}>
                      <div className="m-0">
                        <div style={{ maxHeight: "40vh", overflowY: "scroll" }}>
                          <table class="table table-striped h-4">
                            <thead>
                              <tr>
                                <th>Sr.No</th>
                                <th>Products</th>
                                <th>Qty</th>
                                <th>Supply Price</th>
                                <th>Total Price</th>
                              </tr>
                            </thead>
                            <tbody>
                              {orderDetail.sale_order_details.map(
                                (item, key) => (
                                  <tr tabIndex={key + 1}>
                                    <td>{key + 1}</td>
                                    <td>{item.product.ecom_name || item.product.name}</td>

                                    <td>{item.qty}</td>
                                    <td>
                                      {" "}
                                      {currency.currency_symbol}
                                      {item.unit_price}
                                    </td>
                                    <td>
                                      {currency.currency_symbol}
                                      {(item.unit_price * item.qty).toFixed(2)}
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </Col>
                  ) : (
                    ""
                  )}
                </>
                <Col md={6} lg={6}>
                  <div className="d-flex align-items-center justify-content-between mb-3">
                    <span className="font-weight-bolder font-size-sm mr-2">
                      Order No
                    </span>
                    <span className=" text-right">{orderDetail.no}</span>
                  </div>

                  <div className="d-flex align-items-center justify-content-between mb-3">
                    <span className="font-weight-bolder font-size-sm mr-2">
                      Order By
                    </span>
                    <span className=" text-right">
                      {orderDetail?.created_by?.id == null
                        ? orderDetail?.customer?.business_name
                        : `${orderDetail?.created_by?.first_name}  
                          ${orderDetail?.created_by?.last_name}`}
                    </span>
                  </div>

                  <div className="d-flex align-items-center justify-content-between mb-3">
                    <span className="font-weight-bolder font-size-sm mr-2">
                      Channel Name
                    </span>
                    <span className=" text-right">
                      {orderDetail.channel.name}
                    </span>
                  </div>

                  <div className="d-flex align-items-center justify-content-between mb-3">
                    <span className="font-weight-bolder font-size-sm mr-2">
                      Order Date
                    </span>
                    <span className=" text-right">
                      {formatDateTime(orderDetail.order_date)}
                    </span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mb-3">
                    <span className="font-weight-bolder font-size-sm mr-2">
                      Delivery Date
                    </span>
                    <span className=" text-right">
                      {formatDateTime(orderDetail.pickup_delivery_date)}
                    </span>
                  </div>

                  <div className="d-flex align-items-center justify-content-between mb-3">
                    <span className="font-weight-bolder font-size-sm mr-2">
                      Delivery Charges
                    </span>
                    <span className=" text-right">
                      {currency.currency_symbol}
                      {orderDetail.delivery_charges}
                    </span>
                  </div>

                  <div className="d-flex align-items-center justify-content-between mb-3">
                    <span className="font-weight-bolder font-size-sm mr-2">
                      Tax
                    </span>
                    <span className=" text-right">
                      {orderDetail.tax_type == 2
                        ? `${currency.currency_symbol}${orderDetail.tax}`
                        : `${orderDetail.tax}%`}
                    </span>
                  </div>
                  <hr />
                  <div className="d-flex align-items-center justify-content-between mb-3">
                    <span className="font-weight-bolder font-size-sm mr-2">
                      Sub Total
                    </span>
                    <span className=" text-right">
                      {currency.currency_symbol}
                      {orderDetail.invoice.sub_total}
                    </span>
                  </div>

                  <div className="d-flex align-items-center justify-content-between mb-3">
                    <span className="font-weight-bolder font-size-sm mr-2">
                      Total Amount
                    </span>
                    <span className=" text-right">
                      {currency.currency_symbol}
                      {orderDetail.invoice.total_amount}
                    </span>
                  </div>
                </Col>
              </Row>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    cartItems: state.cartData,
    currency:state.settingData?.["admin-panel"]?.basic||{},
    userDetail: state.userData,
    orderDetail: state.saleData.singleOrder,
  };
};

export default connect(mapStateToProps)(OrderDetailModal);
