import React from "react";

export const addonBefore = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    style={{ marginTop: "7px" }}
  >
    <path
      opacity="0.3"
      d="M19.4999 17.5156L16.3299 14.5156C15.7272 15.2916 15.0049 15.9667 14.1899 16.5156L17.4499 19.6156C17.587 19.7757 17.7565 19.9049 17.9472 19.9947C18.1379 20.0844 18.3455 20.1327 18.5562 20.1363C18.767 20.1399 18.9761 20.0988 19.1697 20.0156C19.3634 19.9325 19.5372 19.8092 19.6797 19.6538C19.8222 19.4985 19.9301 19.3148 19.9963 19.1147C20.0625 18.9146 20.0855 18.7027 20.0638 18.493C20.0421 18.2834 19.9762 18.0807 19.8703 17.8985C19.7645 17.7162 19.6212 17.5584 19.4499 17.4356L19.4999 17.5156Z"
      fill="#808080"
    />
    <path
      d="M9.14014 0.09375C7.36011 0.09375 5.62005 0.621591 4.14001 1.61052C2.65997 2.59946 1.50641 4.00506 0.825225 5.6496C0.144036 7.29413 -0.0341937 9.10373 0.313073 10.8496C0.66034 12.5954 1.51751 14.199 2.77618 15.4577C4.03485 16.7164 5.6385 17.5736 7.38433 17.9208C9.13016 18.2681 10.9398 18.0899 12.5843 17.4087C14.2288 16.7275 15.6344 15.5739 16.6234 14.0939C17.6123 12.6138 18.1401 10.8738 18.1401 9.09375C18.1401 6.7068 17.1919 4.41762 15.5041 2.72979C13.8163 1.04196 11.5271 0.09375 9.14014 0.09375ZM9.14014 15.4538C7.88466 15.4537 6.65735 15.0816 5.61331 14.3843C4.56928 13.687 3.75538 12.6958 3.27447 11.5361C2.79356 10.3764 2.66723 9.10011 2.91143 7.8686C3.15564 6.63709 3.75943 5.50562 4.64649 4.61716C5.53355 3.7287 6.66408 3.12313 7.8952 2.87699C9.12632 2.63084 10.4028 2.75517 11.5633 3.23425C12.7237 3.71333 13.7162 4.52567 14.4151 5.56861C15.1141 6.61154 15.4882 7.83827 15.4901 9.09375C15.4928 9.92885 15.3304 10.7563 15.0123 11.5284C14.6943 12.3006 14.2268 13.0023 13.6368 13.5933C13.0467 14.1843 12.3457 14.6528 11.5741 14.9721C10.8024 15.2914 9.97525 15.4551 9.14014 15.4538Z"
      fill="#808080"
    />
  </svg>
);
