import {
  FETCH_PRODUCTS_SUCCESS,
  FETCH_SINGLE_PRODUCT,
  FETCH_FEATURED_PRODUCT,
  EMPTY_DETAIL_PRODUCT,
  FETCH_SUGGESTED_PRODUCT,
  FETCH_HOTSELLER_PRODUCT,
  FETCH_NEWARRIVALS_PRODUCT,
  SET_LOADING_STATE,
  FETCH_PRODUCT_LOADING_SUCCESS,
  FETCH_PRODUCTS_START,
  FETCH_PRODUCTS_FAILURE,
  UPDATE_SEARCH_KEY
} from "../constant_types";

const initState = {
  products: [],
  featuredProducts: [],
  hotSellerProducts: [],
  newArrivalProducts: [],
  suggestedProducts: [],
  detailProduct: {},
  search_key:"",
  loadingState: false,
  loading:false,
  totalCount: null,
  pageSize: null,
  min_price: null,
  max_price: null,
};

const productReducer = (state = initState, action) => {
  if (action.type === FETCH_PRODUCTS_SUCCESS) {
    if (action.payload.Page) {
      return {
        ...state,
        products: [...action.payload.products.entities],
        totalCount: action.payload.products.totalCount,
        pageSize: action.payload.products.pageSize,
        min_price: action.payload.products.min_price,
        max_price: action.payload.products.max_price,
        loading: false,
      };
    } else {
      return {
        ...state,
        products: [...state.products, ...action.payload.products.entities],
        totalCount: action.payload.products.totalCount,
        pageSize: action.payload.products.pageSize,
        min_price: action.payload.products.min_price,
        max_price: action.payload.products.max_price,
        loading: false,
      };
    }
  }

  if (action.type === FETCH_SINGLE_PRODUCT) {
    return {
      ...state,
      detailProduct: action.payload,
      loading: false,
    };
  }
  if (action.type ===  FETCH_PRODUCTS_START){
      return {
        ...state,
        loading: true,
      };
  }
  if (action.type ===  UPDATE_SEARCH_KEY){
    return {
      ...state,
      search_key: action.payload,
    };
}
  if (action.type === FETCH_PRODUCTS_FAILURE){
    return {
      ...state,
      loading: false,
    };
  }
  if (action.type === FETCH_FEATURED_PRODUCT) {
    return {
      ...state,
      featuredProducts: action.payload,
      loading:false,
    };
  }
  if (action.type === FETCH_NEWARRIVALS_PRODUCT) {
    return {
      ...state,
      newArrivalProducts: action.payload,
      loading:false,
    };
  }
  if (action.type === FETCH_HOTSELLER_PRODUCT) {
    return {
      ...state,
      hotSellerProducts: action.payload,
    };
  }
  if (action.type === FETCH_SUGGESTED_PRODUCT) {
    return {
      ...state,
      suggestedProducts: action.payload.entities,
    };
  }

  if (action.type === EMPTY_DETAIL_PRODUCT) {
    return {
      ...state,
      detailProduct: action.payload,
    };
  }

  if(action.type === SET_LOADING_STATE) {
    return {
      ...state,
      loading: action.payload
    }
  }

  return state;
};

export default productReducer;
