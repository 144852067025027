import React from "react";
import "./stylecustomTextForAgeRestriction.css";
export default function CustomTextForAgeRestriction({text}) {
  return (
    // <div className="label">
    //   <marquee>
    //   <span className="text-wrapper">{text}</span>
    //   </marquee>
    // </div>
    <div className="scrolling-text">
      <marquee>
        <span className="text-wrapper">{text}</span>
      </marquee>
    </div>
  );
}