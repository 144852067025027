import axios from "axios";
import { CATEGORY_URL } from "../../constants/config";
// Constant Types Block
import { FETCH_CATEGORY_TREE } from "../constant_types";
const fetchCategorySuccess = (categoryTree) => ({
  type: FETCH_CATEGORY_TREE,
  payload: categoryTree,
});

// fetch products
export const fetchCategoryTree = (params) => {
  return (dispatch) => {
    axios
      .get(`${CATEGORY_URL}/tree`, { params })
      .then((res) => {
        dispatch(fetchCategorySuccess(res.data));
      })
      .catch((err) => {});
  };
};
