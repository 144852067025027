import React from "react";
import _PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';
import { allCountriesMasks } from "../../constants/index";

export default function PhoneInput(props) {
  return (
    <>
      <_PhoneInput
        country={"us"}
        autoFill="off"
        masks={allCountriesMasks}
        localization={"es"}
        {...props}
        inputStyle={{ height: "45px", maxWidth: "100%" }}
      />
    </>
  );
}
