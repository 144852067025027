import { FETCH_SETTINGS_SUCCESS } from "../actions/storeSettingsActions";

const initState = {
  ecom:{
    basic:{
  facebook: "",
  instagram: "",
  twitter: "",
  youtube: "",
  logo: "",
  favicon: "",
  currency_symbol: "$",
  currency_type: "ARS",
  channel_name: "Primary",
  channel_id: 1,
  tax: 0,
  tax_type: 1,
  delivery_charges: 0,
  currencyRate: 1,
  attributes: [],
  skip_eligibility_check: false,
  ecom_title: "Ecom Site",
  footer_description:"",
  copyrights_description:"",}
}
};

const settingsReducer = (state = initState, action) => {
  if (action.type === FETCH_SETTINGS_SUCCESS) {
    return { ...action.payload };
  }
  return state;
};

export default settingsReducer;
