import currencyReducer from "./currencyReducer";
import productReducer from "./productReducer";
import cartReducer from "./cartReducer";
import wishlistReducer from "./wishlistReducer";
import compareReducer from "./compareReducer";
import settingsReducer from "./settingsReducer";
import categoryReducer from "./categoryReducer";
import tagReducer from "./tagReducers";
import { commonReducer } from "./commonReducer";
import saleReducer from "./checkoutReducer";
import { combineReducers } from "redux";
import { createMultilanguageReducer } from "redux-multilanguage";
import { userReducer } from "./loginReducer";
import { ecomTemplateReducer } from "./ecomTemplateReducer";
import brandReducer from "./brandReducer";

const rootReducer = combineReducers({
  multilanguage: createMultilanguageReducer({ currentLanguageCode: "en" }),
  currencyData: currencyReducer,
  productData: productReducer,
  brandsData:brandReducer,
  cartData: cartReducer,
  wishlistData: wishlistReducer,
  compareData: compareReducer,
  settingData: settingsReducer,
  categoryData: categoryReducer,
  tagData: tagReducer,
  saleData: saleReducer,
  userData: userReducer,
  ecomTemplateData: ecomTemplateReducer,
  generalData: commonReducer,
});

export default rootReducer;
