import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import MetaTags from "react-meta-tags";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { connect } from "react-redux";

import {
  addToCart,
  decreaseQuantity,
  deleteFromCart,
  deleteAllFromCart,
} from "../../redux/actions/cartActions";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import { fetchAllOrders } from "../../redux/actions/checkoutActions";
import { store } from "../..";

const TermsConditions = ({ currency, userDetail, orderDetail }) => {
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({});

  const history = useHistory();
  useEffect(() => {
    setLoading(true);
    store.dispatch(fetchAllOrders(params, userDetail, setLoading));
  }, []);

  return (
    <Fragment>
      <MetaTags>
        <title>{currency?.ecom_title} | Terms & Conditions</title>
        <meta
          name="description"
          content={`Order History page of ${currency?.ecom_title} eCommerce template.`}
        />
      </MetaTags>

      <BreadcrumbsItem to={process.env.PUBLIC_URL + "/"}>Home</BreadcrumbsItem>
      <BreadcrumbsItem to={process.env.PUBLIC_URL + history.location.pathname}>
        Terms & Conditions
      </BreadcrumbsItem>

      <LayoutOne headerTop="visible">
        {/* breadcrumb */}
        <Breadcrumb />
        <div className="art-main-area pt-20 pb-100 pl-20 pr-20">
          <div className="container-fluid">
            <p>
              PLEASE READ THE FOLLOWING TERMS AND CONDITIONS OF USE CAREFULLY
              BEFORE USING THIS WEBSITE. All users of this site agree that
              access to and use of this site are subject to the following terms
              and conditions and other applicable law. If you do not agree to
              these terms and conditions, please do not use this site.
            </p>
            <h4>Copyright:</h4>
            <p>
              The entire content included in this site, including but not
              limited to text, graphics or code is copyrighted as a collective
              work under the United States and other copyright laws, and is the
              property of our company . The collective work includes works that
              are licensed to our company . Copyright 2016-2021, Our company ALL
              RIGHTS RESERVED. Permission is granted to electronically copy and
              print hard copy portions of this site for the sole purpose of
              placing an order with Our company or purchasing Our company
              products. You may display and, subject to any expressly stated
              restrictions or limitations relating to specific material,
              download or print portions of the material from the different
              areas of the site solely for your own non-commercial use, or to
              place an order with Our company or to purchase Our company
              products. Any other use, including but not limited to the
              reproduction, distribution, display or transmission of the content
              of this site is strictly prohibited, unless authorized by our
              company . You further agree not to change or delete any
              proprietary notices from materials downloaded from the site.
            </p>
            <h4>Trademarks:</h4>
            <p>
              All trademarks, service marks and trade names of Our company used
              in the site are trademarks or registered trademarks of our company{" "}
            </p>
            <h4>Warranty Disclaimer:</h4>
            <p>
              This site and the materials and products on this site are provided
              "as is" and without warranties of any kind, whether express or
              implied. To the fullest extent permissible pursuant to applicable
              law, our company disclaims all warranties, express or implied,
              including, but not limited to, implied warranties of
              merchantability and fitness for a particular purpose and
              non-infringement. Our company does not represent or warrant that
              the functions contained in the site will be uninterrupted or
              error-free, that the defects will be corrected, or that this site
              or the server that makes the site available are free of viruses or
              other harmful components. Our company does not make any warrantees
              or representations regarding the use of the materials in this site
              in terms of their correctness, accuracy, adequacy, usefulness,
              timeliness, reliability or otherwise. Some states do not permit
              limitations or exclusions on warranties, so the above limitations
              may not apply to you.
            </p>
            <h4>Limitation of Liability:</h4>
            <p>
              Our company shall not be liable for any special or consequential
              damages that result from the use of, or the inability to use, the
              materials on this site or the performance of the products, even if
              Our company has been advised of the possibility of such damages.
              Applicable law may not allow the limitation of exclusion of
              liability or incidental or consequential damages, so the above
              limitation or exclusion may not apply to you.
            </p>
            <h4>Typographical Errors:</h4>
            <p>
              If Our company product is mistakenly listed at an incorrect price,
              reserves the right Our company to refuse or cancel any orders
              placed for product listed at the incorrect price. Our company
              reserves the right to refuse or cancel any such orders whether or
              not the order has been confirmed and your credit card charged. If
              your credit card has already been charged for the purchase and
              your order is cancelled, our company shall issue a credit to your
              credit card account in the amount of the incorrect price.
            </p>
            <h4>Term Termination:</h4>
            <p>
              These terms and conditions are applicable to you upon your
              accessing the site and/or completing the registration or shopping
              process. These terms and conditions, or any part of them, may be
              terminated by Our company without notice at any time, for any
              reason. The provisions relating to Copyrights, Trademark,
              Disclaimer, Limitation of Liability, Indemnification and
              Miscellaneous, shall survive any termination.
            </p>
            <h4>Notice:</h4>
            <p>
              Our company may deliver notice to you by means of e-mail, a
              general notice on the site, or by other reliable method to the
              address you have provided to our company .
            </p>
            <h4>Miscellaneous:</h4>
            <p>
              Your use of this site shall be governed in all respects by the
              laws of the state of Florida, U.S.A., without regard to choice of
              law provisions, and not by the 1980 U.N. Convention on contracts
              for the international sale of goods. You agree that jurisdiction
              over and venue in any legal proceeding directly or indirectly
              arising out of or relating to this site (including but not limited
              to the purchase of Our company products) shall be in the state or
              federal courts located in clearwater, Florida. Any cause of action
              or claim you may have with respect to the site (including but not
              limited to the purchase of myvaporstore.com products) must be
              commenced within one (1) year after the claim or cause of action
              arises. Our company failure to insist upon or enforce strict
              performance of any provision of these terms and conditions shall
              not be construed as a waiver of any provision or right. Neither
              the course of conduct between the parties nor trade practice shall
              act to modify any of these terms and conditions. Our company may
              assign its rights and duties under this Agreement to any party at
              any time without notice to you.
            </p>
            <h4>Use of Site:</h4>
            <p>
              Harassment in any manner or form on the site, including via
              e-mail, chat, or by use of obscene or abusive language, is
              strictly forbidden. Impersonation of others, including an Our
              company or other licensed employee, host, or representative, as
              well as other members or visitors on the site is prohibited. You
              may not upload to, distribute, or otherwise publish through the
              site any content which is libellous, defamatory, obscene,
              threatening, invasive of privacy or publicity rights, abusive,
              illegal, or otherwise objectionable which may constitute or
              encourage a criminal offense, violate the rights of any party or
              which may otherwise give rise to liability or violate any law. You
              may not upload commercial content on the site or use the site to
              solicit others to join or become members of any other commercial
              online service or other organization.
            </p>
            <h4>Participation Disclaimer:</h4>
            <p>
              Our company does not and cannot review all communications and
              materials posted to or created by users accessing the site and is
              not in any manner responsible for the content of these
              communications and materials. You acknowledge that by providing
              you with the ability to view and distribute user-generated content
              on the site, our company is merely acting as a passive conduit for
              such distribution and is not undertaking any obligation or
              liability relating to any contents or activities on the site.
              However, myvaporstore.com reserves the right to block or remove
              communications or materials that it determines to be (a) abusive,
              defamatory, or obscene, (b) fraudulent, deceptive, or misleading,
              (c) in violation of a copyright, trademark or; other intellectual
              property right of another or (d) offensive or otherwise
              unacceptable to Our company in its sole discretion.
            </p>
            <h4>Indemnification:</h4>
            <p>
              You agree to indemnify, defend, and hold harmless our company ,
              its officers, directors, employees, agents, licensors and
              suppliers (collectively the "Service Providers") from and against
              all losses, expenses, damages and costs, including reasonable
              attorneys' fees, resulting from any violation of these terms and
              conditions or any activity related to your account (including
              negligent or wrongful conduct) by you or any other person
              accessing the site using your Internet account.
            </p>
            <h4>Third-Party Links:</h4>
            <p>
              In an attempt to provide increased value to our visitors, Our
              company may link to sites operated by third parties. However, even
              if the third party is affiliated with our company , our company
              has no control over these linked sites, all of which have separate
              privacy and data collection practices, independent of our company
              . These linked sites are only for your convenience and therefore
              you access them at your own risk. Nonetheless, our company seeks
              to protect the integrity of its web site and the links placed upon
              it and therefore requests any feedback on not only its own site,
              but for sites it links to as well (including if a specific link
              does not work).
            </p>
            <h4>Disclaimer:</h4>
            <p>
              These products are not intended to diagnose, treat, cure or
              prevent any disease. Information and statements made are for
              educational purposes and are not intended to replace the advice of
              your health care professional. By purchasing these products, you
              agree to use products at your own risk. Under no circumstances
              will Our company be held responsible or liable, directly or
              indirectly, for any loss or damage that is caused or alleged to
              have been caused in connection with use of, or reliance on any
              product on this site. Our products are intended for ADULTS only.
              Our Products are not to be used by anyone under the legal smoking
              age of the state they reside in. Other Groups that should consult
              a physician before use Included (but are not limited to): Those
              who suffer from heart disease or high blood pressure. Pregnant,
              nursing, or those thinking of becoming pregnant. Those allergic to
              nicotine or other PG based products
            </p>
            <h4>Product Image Disclaimer:</h4>
            <p>
              Product images are for illustration purposes only. Actual product
              colours may vary depending on screen resolution and lighting.
            </p>
            <h4>NOT FOR SALE TO MINORS:</h4>
            <p>
              Products sold on this site may contain nicotine which is a highly
              addictive substance. As per FLORIDA Law and Regulation-
            </p>
            <h4>WARNING:</h4>
            <p>
              Our products contain nicotine, a poisonous and addictive
              substance. Our products are only intended for committed smokers of
              legal smoking age and not by non-smokers, children, women who are
              pregnant or may become pregnant or any person with an elevated
              risk of, or pre-existing condition of, any medical condition which
              includes, but is not limited to, heart disease, diabetes, high
              blood pressure or asthma. If you experience any side effects or
              possible side effects, stop using the product immediately and
              consult a physician. Product may be poisonous if orally ingested.
              Our products are not smoking cessation products and have not been
              tested or guaranteed as such. Our products have not been evaluated
              by the Food and Drug Administration nor is it intended to treat,
              prevent or cure any disease or condition. For their protection,
              please keep out of reach of children and pets. Read our terms of
              use page before purchasing our products.
            </p>
          </div>
        </div>
      </LayoutOne>
    </Fragment>
  );
};

TermsConditions.propTypes = {
  addToCart: PropTypes.func,
  cartItems: PropTypes.array,
  currency: PropTypes.object,
  orderDetail: PropTypes.object,
  singleOrder: PropTypes.object,
  userDetail: PropTypes.object,
  decreaseQuantity: PropTypes.func,
  deleteAllFromCart: PropTypes.func,
  deleteFromCart: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    cartItems: state.cartData,
    currency:state.settingData?.["admin-panel"]?.basic||{},
    userDetail: state.userData,
    orderDetail: state.saleData.orderEntities,
    singleOrder: state.saleData.singleOrder,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (item, addToast, quantityCount) => {
      dispatch(addToCart(item, addToast, quantityCount));
    },
    decreaseQuantity: (item, addToast) => {
      dispatch(decreaseQuantity(item, addToast));
    },
    deleteFromCart: (item, addToast) => {
      dispatch(deleteFromCart(item, addToast));
    },
    deleteAllFromCart: (addToast) => {
      dispatch(deleteAllFromCart(addToast));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TermsConditions);
