
export default function Player() {
  const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
  let frequency = 1400;
  let type = "sawtooth"
  let volume = 100;
  let duration = 100; // ms
  function playAudio(props) {
    if (props === "error") {
      frequency = 100;
      type = "square"
      volume = 100;
      duration = 100;
    }
    if (props == 'warning') {
      frequency = 700;
      type = "sawtooth"
      volume = 100;
      duration = 100;
    }
    var oscillator = audioCtx.createOscillator();
    var gainNode = audioCtx.createGain();

    oscillator.connect(gainNode);
    gainNode.connect(audioCtx.destination);

    gainNode.gain.value = volume;
    oscillator.frequency.value = frequency;
    oscillator.type = type;

    oscillator.start();

    setTimeout(
      function () {
        oscillator.stop();
      },
      duration
    );
  };

  return [playAudio];
}

