import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Dropdown } from "antd";
import { deleteFromCart } from "../../redux/actions/cartActions";
import { getMenuItems } from "../utils/menuItems";
import { handleUserLogout } from "../utils/authUtils";

const IconGroup = ({ cartData, userDetail }) => {
  const history = useHistory();
  const items = getMenuItems(history, () => handleUserLogout(history));
  

  

  const [cartDropdownVisible, setCartDropdownVisible] = useState(false);
  const [userDropdownVisible, setUserDropdownVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setCartDropdownVisible(false);
      setUserDropdownVisible(false);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [dropdownVisible, setDropdownVisible] = useState(false);

  // Function to toggle the visibility of the dropdown
  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };
  return (
    <div className={`header-right-wrap header-margin`}>
      {!userDetail?.user ? (
        <>
        <div style={{display:"flex", borderLeft:"2px solid", borderLeftColor:"#E6E6E6", marginRight:"10px", marginTop:"7px"}}>
        </div>
        <div
          onClick={() => {
            history.push(`/login`);
          }}
          style={{marginTop:"7px", cursor:"pointer"}}
        >
          Login / Signup
        </div>
        </>
      ) : (
        <>
          <div
            style={{
              position: "relative",
              display: "inline-block",
            }}
          >
            <div style={{display:"flex", borderLeft:"2px solid", borderLeftColor:"#E6E6E6"}}>

              <svg 
                className="cart-icon-logo cursor-pointer"
                xmlns="http://www.w3.org/2000/svg" 
                width="30" 
                height="30"
                viewBox="0 0 24 24" 
                fill="none"
                onClick={() => {
                  history.push(`/cart`);
                }}
              >
                <g id="ion:cart-outline">
                  <path id="Vector" d="M8.25 20.25C8.66421 20.25 9 19.9142 9 19.5C9 19.0858 8.66421 18.75 8.25 18.75C7.83579 18.75 7.5 19.0858 7.5 19.5C7.5 19.9142 7.83579 20.25 8.25 20.25Z" stroke={'var(--style-nav-text-color-regular, #000000)'} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path id="Vector_2" d="M18.75 20.25C19.1642 20.25 19.5 19.9142 19.5 19.5C19.5 19.0858 19.1642 18.75 18.75 18.75C18.3358 18.75 18 19.0858 18 19.5C18 19.9142 18.3358 20.25 18.75 20.25Z" stroke={'var(--style-nav-text-color-regular, #000000)'} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path id="Vector_3" d="M2.25 3.75H5.25L7.5 16.5H19.5" stroke={'var(--style-nav-text-color-regular, #000000)'} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path id="Vector_4" d="M7.5 13.5H19.1925C19.2792 13.5001 19.3633 13.4701 19.4304 13.4151C19.4975 13.3601 19.5434 13.2836 19.5605 13.1986L20.9105 6.44859C20.9214 6.39417 20.92 6.338 20.9066 6.28414C20.8931 6.23029 20.8679 6.18009 20.8327 6.13717C20.7975 6.09426 20.7532 6.05969 20.703 6.03597C20.6528 6.01225 20.598 5.99996 20.5425 6H6" stroke={'var(--style-nav-text-color-regular, #000000)'} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
              </svg>
            <div className="cursor-pointer" style={{marginTop:"5px", marginLeft:"10px", marginRight:"10px"}} onClick={() => {
                history.push(`/cart`);
              }}>View Cart</div>
            </div>
            <div
              style={{
                position: "absolute",
                top: "-8px",
                right: "8px",
                backgroundColor: "var(--style-primary-color)",
                color: "white",
                borderRadius: "50%",
                padding: "5px",
                fontSize: "12px",
                height: "22px",
                // width: "22px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span>
                {cartData.filter((el) => el.quantity > 0).reduce((total, item) => total + item.quantity, 0) <= 0 ? 0 :
                  cartData.filter((el) => el.quantity > 0).reduce((total, item) => total + item.quantity, 0)}
              </span>
            </div>
          </div>
          <Dropdown
            className="header-profile-dropdown"
            menu={{
              items,
            }}
            trigger={["click"]}
            visible={dropdownVisible}
            onVisibleChange={setDropdownVisible}
          >
            <div style={{display:"flex", borderLeft:"2px solid", borderLeftColor:"#E6E6E6"}}>
              <svg xmlns="http://www.w3.org/2000/svg" className="profile-header-logo cursor-pointer" width="24" height="30" viewBox="0 0 24 24" fill="none">
                <g id="mdi:user">
                <path stroke-width="1.5" stroke={'var(--style-nav-text-color-regular, #000000)'} id="Vector" d="M12 4.5C12.9283 4.5 13.8185 4.86875 14.4749 5.52513C15.1313 6.1815 15.5 7.07174 15.5 8C15.5 8.92826 15.1313 9.8185 14.4749 10.4749C13.8185 11.1313 12.9283 11.5 12 11.5C11.0717 11.5 10.1815 11.1313 9.52513 10.4749C8.86875 9.8185 8.5 8.92826 8.5 8C8.5 7.07174 8.86875 6.1815 9.52513 5.52513C10.1815 4.86875 11.0717 4.5 12 4.5ZM12 14.5C14.1493 14.5 16.0695 14.9363 17.4339 15.6185C18.8281 16.3155 19.5 17.1863 19.5 18V19.5H4.5V18C4.5 17.1863 5.17194 16.3155 6.56611 15.6185C7.9305 14.9363 9.85073 14.5 12 14.5Z"/>
                </g>
              </svg>
            </div>
          </Dropdown>
          <div onClick={toggleDropdown} className="cursor-pointer" style={{ fontSize: "17px", display: "flex", alignItems: "center", marginLeft:"10px", }}>
            <div style={{opacity: 0.7}}>Welcome to,</div>{' '}
            {userDetail.user.business_name.split(" ")[0]}
          </div>
        </>
      )}
    </div>
  );
};

IconGroup.propTypes = {
  cartData: PropTypes.array,
  deleteFromCart: PropTypes.func,
  userDetail: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    cartData: state.cartData,
    userDetail: state.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteFromCart: (cartItems, item, channel_id, addToast) => {
      dispatch(deleteFromCart(cartItems, item, channel_id, addToast));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IconGroup);
