import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Col, Drawer, Dropdown, Row, Space } from "antd";
import {  useHistory } from "react-router-dom";
import NavMenu from "./NavMenu";
import { connect } from "react-redux";
import { Skeleton } from "antd";
import { getMenuItems } from "../utils/menuItems";
import { handleUserLogout } from "../utils/authUtils";
import HomeSearch from "./HomeSearch";

import { SearchOutlined, UserOutlined } from '@ant-design/icons';

const Logo = ({ logoClass, settingData, cartData, userDetail }) => {
  const [visible, setVisible] = useState(false);
  const history = useHistory();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const toggleDrawer = () => {
    setVisible(!visible); // Toggle visibility state
  };
  const [isLoading, setLoading] = useState(true);

  const items = getMenuItems(history, () => handleUserLogout(history));

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1365) {
        setVisible(false); // Close the drawer when the window is wider than 850px
      }
    };

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);
  
    return (
      <>
        <Space size={"large"} className=" menu-toggle-icon">
          {!userDetail?.user ? (
            <div
              onClick={() => {
                history.push(`/login`);
              }}
              style={{ cursor: "pointer", marginRight: 10 }}
            >
              Login / Signup
            </div>
          ) : (
            <Space
              size={"large"}
              className="icons-for-small-and-medium-devices"
            >
              <SearchOutlined
                style={{ fontSize: 24 }}
                className="cursor-pointer"
                onClick={toggleDrawer}
              />
              <Dropdown
                overlayClassName="header-profile-dropdown-form-small-devices"
                menu={{
                  items,
                }}
                trigger={["click"]}
                overlayStyle={{}}
              >
                <UserOutlined
                  style={{ fontSize: 24 }}
                  className="cursor-pointer"
                />
              </Dropdown>
            </Space>
          )}
          <Drawer
            placement="top"
            closable={true}
            visible={visible} // Controlled by state
            closeIcon={false}
            onClose={() => toggleDrawer(0, false)}
            headerStyle={{ display: "none", zIndex: 1000 }}
            width={300}
            height={500}
          >
            <Row justify={"space-between"}>
              <Col>
                <Link to={process.env.PUBLIC_URL + "/home"}>
                  <img
                    style={{ width: "97.582px", height: "30.341px" }}
                    alt="Logo"
                    src={process.env.PUBLIC_URL + "/media/icons/logo.svg"}
                  />
                </Link>
              </Col>
              <Col>
                <img
                  src={process.env.PUBLIC_URL + "/media/icons/crossicon.svg"}
                  alt="sidebar icon"
                  style={{
                    cursor: "pointer",
                    padding: "8.926px 8.939px 8.926px 8.94px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={toggleDrawer}
                />
              </Col>
            </Row>
            <div className="small-devices-search-bar">
              {isLoading ? (
                <Skeleton.Button
                  active
                  size="small"
                  shape="round"
                  style={{ marginTop: "20%", width: "200px" }}
                />
              ) : (
                <HomeSearch isMobile={true} />
              )}
            </div>
            <NavMenu isMobile={true} settingData={settingData} />
            <hr />
            <div className={`header-left-wrap-for-medium-devices `}>
              {!userDetail?.user ? (
                <div
                onClick={() => {
                  history.push(`/login`);
                }}
                style={{ cursor: "pointer", marginRight: 10 }}
              >
                Login / Signup
              </div>
              ) : (
                <div className="mobile-bottom-menu">
                  <div
                    style={{
                      position: "relative",
                      display: "inline-block",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        borderLeftColor: "#E6E6E6",
                      }}
                    >
                      <img
                        className="cart-icon-logo cursor-pointer"
                        src={`${process.env.PUBLIC_URL}/media/icons/cartonline.svg`}
                        onClick={() => {
                          history.push(`/cart`);
                        }}
                        alt="cart"
                      />
                      <div
                        className="cursor-pointer"
                        style={{
                          marginTop: "5px",
                          marginLeft: "10px",
                          marginRight: "10px",
                        }}
                        onClick={() => {
                          history.push(`/cart`);
                        }}
                      >
                        View Cart
                      </div>
                    </div>
                    <div
                    className="view-cart-count"
                      style={{
                        position: "absolute",
                        top: "-2px",
                        right: "86px",
                        backgroundColor: "#EA1E51",
                        color: "white",
                        borderRadius: "50%",
                        fontWeight: 500,
                        padding: "5px",
                        fontSize: "8px",
                        height: "18px",
                        // width: "22px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <span>
                        {cartData
                          .filter((el) => el.quantity > 0)
                          .reduce((total, item) => total + item.quantity, 0) <=
                        0
                          ? 0
                          : cartData
                              .filter((el) => el.quantity > 0)
                              .reduce(
                                (total, item) => total + item.quantity,
                                0
                              )}
                      </span>
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <Dropdown
                      className="header-profile-dropdown"
                      menu={{
                        items,
                      }}
                      trigger={["click"]}
                      visible={dropdownVisible}
                      onVisibleChange={setDropdownVisible}
                    >
                      <div
                        style={{
                          display: "flex",
                          borderLeftColor: "#E6E6E6",
                        }}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" className="profile-header-logo cursor-pointer" width="24" height="30" viewBox="0 0 24 24" fill="none">
                          <g id="mdi:user">
                          <path stroke={'var(--style-nav-text-color-regular, #000000)'} id="Vector" d="M12 4.5C12.9283 4.5 13.8185 4.86875 14.4749 5.52513C15.1313 6.1815 15.5 7.07174 15.5 8C15.5 8.92826 15.1313 9.8185 14.4749 10.4749C13.8185 11.1313 12.9283 11.5 12 11.5C11.0717 11.5 10.1815 11.1313 9.52513 10.4749C8.86875 9.8185 8.5 8.92826 8.5 8C8.5 7.07174 8.86875 6.1815 9.52513 5.52513C10.1815 4.86875 11.0717 4.5 12 4.5ZM12 14.5C14.1493 14.5 16.0695 14.9363 17.4339 15.6185C18.8281 16.3155 19.5 17.1863 19.5 18V19.5H4.5V18C4.5 17.1863 5.17194 16.3155 6.56611 15.6185C7.9305 14.9363 9.85073 14.5 12 14.5Z"/>
                          </g>
                        </svg>
                      </div>
                    </Dropdown>
                    <div
                      onClick={toggleDropdown}
                      className="cursor-pointer"
                      style={{
                        fontSize: "17px",
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "10px",
                      }}
                    >
                      <div style={{ opacity: "0.7" }}>Welcome to,</div>{" "}
                      {userDetail.user.business_name.split(" ")[0]}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Drawer>
        </Space>
        <div className={`${logoClass ? logoClass : ""}`}>
          <div style={{ display: "flex" }}>
            <img
              className="sidebaricon"
              src={process.env.PUBLIC_URL + "/media/icons/sidebaricon.svg"}
              onClick={toggleDrawer}
              alt="sidebar icon"
              style={{ cursor: "pointer", marginRight: "10px" }}
            />
          </div>
        </div>
      </>
    );
};

Logo.propTypes = {
  logoClass: PropTypes.string,
  settingData: PropTypes.object,
  cartData: PropTypes.array,
  userDetail: PropTypes.object,
};


const mapStateToProps = (state) => {
  return {
    settingData: state.settingData.ecom.basic,
    cartData: state.cartData,
    userDetail: state.userData,
  };
};

export default connect(mapStateToProps)(Logo);
