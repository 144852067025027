import React from "react";
import Countdown from "react-countdown";
import { getTimeByTimeZonePromotion } from "../../pages/constant/utils";
import moment from "moment";
import "./style.css";


export default function PromotionsTimer({ product, heading, showNoPromo, settings }) {
  const dateTimeFormat = "YYYY-MM-DDTHH:mm:ss";
  let targetDate = null;

  if (product?.selected_unit_price?.promotion?.end_datetime) {
    targetDate = moment(getTimeByTimeZonePromotion(settings?.timezone,product.selected_unit_price.promotion.end_datetime)).format(dateTimeFormat)
  } else if (product?.variant_product?.length) {
    targetDate = getTimeByTimeZonePromotion(
      settings?.timezone,
      product.variant_product.find(
        variant => variant?.selected_unit_price?.promotion?.end_datetime
      )?.selected_unit_price?.promotion?.end_datetime
    );
  }

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return null;
    } else {
      return (
        <div>
          {heading && <p className="promo-heading">Promotion ends in:</p>}
          {days > 0 && <span className="promo-timer">{days} Days </span>}
          {hours > 0 && <span className="promo-timer">{hours < 10 ? `0${hours}` : hours} Hours </span>}
          {minutes > 0 && <span className="promo-timer">{minutes < 10 ? `0${minutes}` : minutes} Mins </span>}
          {seconds > 0 && <span className="promo-timer">{seconds < 10 ? `0${seconds}` : seconds} Sec</span>}
        </div>
      );
    }
  };

  if (showNoPromo && !targetDate) {
    return <p style={{color:"red"}}>Not on Promotion</p>;
  }

  return (
    <>
      <Countdown
        date={targetDate}
        renderer={renderer}
      />
    </>
  );
}
