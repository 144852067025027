export const Stage = {
  LOCAL: "local",
  DEV: "dev",
  STAGING: "staging",
  PROD: "production",
};

export const UnitValue= {
  PIECE : 1,
  PACK : 2,
  CASE : 3,
  PALLET : 4,
}

export const UNIT = {
  [UnitValue.PIECE]: { name: "Piece", value: UnitValue.PIECE, subId: null },
  [UnitValue.PACK]: { name: "Pack", value: UnitValue.PACK, subType: "piece", subId: 1 },
  [UnitValue.CASE]: { name: "Case", value: UnitValue.CASE, subType: "pack", subId: 2 },
  [UnitValue.PALLET]: { name: "Pallet", value: UnitValue.PALLET, subType: "case", subId: 3 },
};

export const getUnitNameByValue = (value)  => {
  return UNIT[value]?.name || 'Unknown';
};

export const UNIT_OPTIONS = Object.values(UNIT);


// All Countries Masks
export const allCountriesMasks = {
  ac: "....",
  ad: "...-...",
  ae: ".-...-....",
  af: "..-...-....",
  ag: "...-....",
  ai: "...-....",
  al: "(...) ...-...",
  am: "..-...-...",
  an: "...-....",
  ao: "(...) ...-...",
  aq: "..-...",
  ar: "(...) ...-....",
  as: "(...) ...-....",
  at: "(...) ...-....",
  au: ".-....-....",
  aw: "...-....",
  az: "..-...-..-..",
  ba: "..-....",
  bb: "...-....",
  bd: "..-...-...",
  be: "(...) ...-...",
  bf: "..-..-....",
  bg: "(...) ...-...",
  bh: "....-....",
  bi: "..-..-....",
  bj: "..-..-....",
  bm: "(...) ...-....",
  bn: "...-....",
  bo: ".-...-....",
  br: "(..) .....-....",
  bs: "(...) ...-....",
  bt: ".-...-...",
  bw: "..-...-...",
  by: "(..) ...-..-..",
  bz: "...-....",
  ca: "(...) ...-....",
  cd: "(...) ...-...",
  cf: "..-..-....",
  cg: "..-...-....",
  ch: "..-...-....",
  ci: "..-...-...",
  ck: "..-...",
  cl: ".-....-....",
  cm: "....-....",
  cn: "..-.....-.....",
  co: "(...) ...-....",
  cr: "....-....",
  cu: ".-...-....",
  cv: "(...) ..-..",
  cw: "...-....",
  cy: "..-...-...",
  cz: "(...) ...-...",
  de: "...-...",
  dj: "-..-..-..-..",
  dk: "..-..-..-..",
  dm: "(...) ...-....",
  do: "(...) ...-....",
  dz: "..-...-....",
  ec: ".-...-....",
  ee: "...-....",
  eg: "(...) ...-....",
  er: ".-...-...",
  es: "(...) ...-...",
  et: "-..-...-....",
  fi: "(...) ...-..-..",
  fj: "..-.....",
  fk: ".....",
  fm: "...-....",
  fo: "...-...",
  fr: "(...) ...-...",
  ga: ".-..-..-..",
  gd: "(...) ...-....",
  ge: "(...) ...-...",
  gf: ".....-....",
  gh: "(...) ...-...",
  gi: "...-.....",
  gl: "..-..-..",
  gm: "(...) ..-..",
  gn: "..-...-...",
  gq: "..-...-....",
  gr: "(...) ...-....",
  gt: ".-...-....",
  gu: "(...) ...-....",
  gw: ".-......",
  gy: "...-....",
  hk: "....-....",
  hn: "....-....",
  hr: "..-...-...",
  ht: "..-..-....",
  hu: "(...) ...-...",
  id: "(...) ...-..-...",
  ie: "(...) ...-...",
  il: ".-...-....",
  in: ".....-.....",
  io: "...-....",
  iq: "(...) ...-....",
  ir: "(...) ...-....",
  is: "...-....",
  it: "(...) ....-...",
  jm: "(...) ...-....",
  jo: ".-....-....",
  jp: "(...) ...-...",
  ke: "-...-......",
  kg: "(...) ...-...",
  kh: "..-...-...",
  ki: "..-...",
  km: "..-.....",
  kn: "(...) ...-....",
  kp: "-....-.............",
  kr: "..-...-....",
  kw: "....-....",
  ky: "(...) ...-....",
  kz: "(...) ...-..-..",
  la: "..-...-...",
  lb: ".-...-...",
  lc: "(...) ...-....",
  li: "(...) ...-....",
  lk: "..-...-....",
  lr: "..-...-...",
  ls: ".-...-....",
  lt: "(...) ..-...",
  lu: "(...) ...-...",
  lv: "..-...-...",
  ly: "...-....",
  ma: "..-....-...",
  mc: "..-...-...",
  md: "....-....",
  me: "..-...-...",
  mg: "..-..-.....",
  mh: "...-....",
  mk: "..-...-...",
  ml: "..-..-....",
  mm: "...-...",
  mn: "-..-..-....",
  mo: "....-....",
  mp: "(...) ...-....",
  mq: "(...) ..-..-..",
  mr: "..-..-....",
  ms: "(...) ...-....",
  mt: "....-....",
  mu: "...-....",
  mv: "...-....",
  mw: ".-....-....",
  mx: "... ... ....",
  my: ".-...-...",
  mz: "..-...-...",
  na: "..-...-....",
  nc: "..-....",
  ne: "..-..-....",
  nf: "...-...",
  ng: "(...) ...-....",
  ni: "....-....",
  nl: "..-...-....",
  no: "(...) ..-...",
  np: "..-...-...",
  nr: "...-....",
  nu: "....",
  nz: "(...) ...-....",
  om: "..-...-...",
  pa: "-...-....",
  pe: "(...) ...-...",
  pf: "..-..-..",
  pg: "(...) ..-...",
  ph: "(...) ...-....",
  pk: "...-.......",
  pl: "(...) ...-...",
  ps: "..-...-....",
  pt: "..-...-....",
  pw: "...-....",
  py: "(...) ...-...",
  qa: "....-....",
  re: ".....-....",
  ro: "..-...-....",
  rs: "..-..-...-....",
  ru: "(...) ...-..-..",
  rw: "(...) ...-...",
  sa: "-.-...-....",
  sb: ".....",
  sc: ".-...-...",
  sd: "-..-...-....",
  se: "..-...-....",
  sg: "....-....",
  sh: "....",
  si: "..-...-...",
  sk: "(...) ...-...",
  sl: "..-......",
  sm: "....-......",
  sn: "..-...-....",
  so: ".-...-...",
  sr: "...-...",
  ss: "..-...-....",
  st: "..-.....",
  sv: "..-..-....",
  sx: "(...) ...-....",
  sy: "..-....-...",
  sz: "..-..-....",
  tc: "(...) ...-....",
  td: "..-..-..-..",
  tg: "..-...-...",
  th: "..-...-...",
  tj: "..-...-....",
  tk: "....",
  tl: "...-.....",
  tm: ".-...-....",
  tn: "..-...-...",
  to: ".....",
  tr: "(...) ...-....",
  tt: "(...) ...-....",
  tv: ".....",
  tw: "....-....",
  tz: " ..-...-....",
  ua: "(..) ...-..-..",
  ug: "... ...-...",
  uk: ".... ......",
  us: "(...) ...-....",
  uy: ".-...-..-..",
  uz: "..-...-....",
  va: ".-...-.....",
  vc: "(...) ...-....",
  ve: ".(...) ...-....",
  vg: "(...) ...-....",
  vi: "(...) ...-....",
  vn: "(...) ....-...",
  vu: ".....",
  wf: "..-....",
  ws: "..-....",
  ye: "..-...-...",
  za: "..-...-....",
  zm: "..-...-....",
  zw: ".-......",
};