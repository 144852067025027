import React, { createContext, useContext, useState } from "react";

const UIContext = createContext();

export function useUIContext() {
  return useContext(UIContext);
}

export function UIProvider({ children }) {
  const [requiredLengthBilling, setRequiredLengthBilling] = useState(17);
  const [requiredLengthShipping, setRequiredLengthShipping] = useState(17);
  const [requiredLength, setRequiredLength] = useState(17);
  const value = {
    requiredLengthBilling: requiredLengthBilling,
    setRequiredLengthBilling: setRequiredLengthBilling,
    requiredLengthShipping: requiredLengthShipping,
    setRequiredLengthShipping: setRequiredLengthShipping,
    requiredLength: requiredLength,
    setRequiredLength: setRequiredLength,

  };

  return <UIContext.Provider value={value}>{children}</UIContext.Provider>;
}
