import { FETCH_ECOM_TEMPLATE_SUCCESS } from "../actions/ecomTemplateActions";

const initState = {
  loading: false,
  entity: {
    data: {
      slider_images: {
        image_1: "",
        image_2: "",
      },
      home_images: {
        image_1: "",
        image_2: "",
      },
    },
  },
  message: {
    success: null,
    error: null,
  },
};

export const ecomTemplateReducer = (state = initState, action) => {
  if (action.type === FETCH_ECOM_TEMPLATE_SUCCESS) {
    return { ...state, entity: action.payload };
  }

  return state;
};
