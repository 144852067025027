import { Form, Spin, notification } from "antd";
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { ErrorMessage, Field, Formik } from "formik";
import { Input } from "../../components/form/Input";
import EditIcon from "../../../src/assets/images/edit-icon.svg";
import EyeIcon from "../../../src/assets/images/eye.svg";
import Button from "../../components/utils/Button";
import { getDiscountPrice } from "../../helpers/product";
import {
  saleOrderPayload,
  paymentType,
  Units
} from "../constant/Constants";
import {
  backendFormatDateTime,
  formatCurrency,
  getPriceCheckout,
  getPriceRelatedToCustomer,
} from "../constant/utils";
import "moment-timezone";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useMainUIContext } from "../../UIContext";
import PromotionsPrice from "../../components/common/PromotionPrice";



const PaymentMethod = ({
  formRef,
  openPaymentinfo,
  buttonStyle,
  activeTab,
  loadingBank,
  bankInfo,
  openAchModal,
  openViewAchModal,
  userDetail,
  maskAccountNumber,
  saleTotal,
  additionalNotes,
  setAdditionalNote,
  adminSettings,
  handleClick,
  Items,
  settings,
  calculation,
  prevPage
}) => {
  console.log("🚀 ~ Items:", Items)
  const mainUIContext = useMainUIContext();
  const mainUIProps = useMemo(() => {
    return {
      checkoutShippingDetails: mainUIContext.checkoutShippingDetails,
      setCheckoutShippingDetails: mainUIContext.setCheckoutShippingDetails,
    };
  }, [mainUIContext]);
  const { setCheckoutShippingDetails } = mainUIProps;

  const { tax, tax_type, delivery_charges, payments } = adminSettings.pos || {}
  const noteEcomLength=500
  const {
    currencyRate,
  } = settings || {};
  const { currency_symbol, timezone } = adminSettings.basic
  let cartTotalPrice = 0;
  const renderedProductTaxes = new Set();
  const saleOrderDetails = calculation?.sale_order_details || [];

  // Collect all unique tax names
  const uniqueTaxNames = [
    ...new Set(
      saleOrderDetails?.flatMap(item =>
        item?.sale_order_detail_taxes?.map(product_tax => product_tax?.tax.name)
      )
    ),
  ];

  const getLineItemtaxeAmpount = (key) => {
    const findedIndex = saleOrderDetails[key]
    if(findedIndex) {
      try {
        return saleOrderDetails[key]?.sale_order_detail_taxes.map(( { amount } ) => amount).reduce((total, amount) => total + amount, 0)
      } catch (err) {
        return 0
      }
    } return 0
  }
  const uniqueTaxValues = 0 
  // saleOrderDetails?.map(item => item?.sale_order_detail_taxes?.map(product_tax => product_tax?.amount)).reduce((total, amount) => total + amount, 0)
  console.log("🚀 ~ uniqueTaxValues:", uniqueTaxValues)
  return (
    <Formik
      initialValues={{
        ...saleOrderPayload,
        dispatch_date: backendFormatDateTime(Date.now()),
      }}
      innerRef={formRef}
    >
      {({ values, setFieldValue, handleSubmit, setValues }) => (
        <>
          <div className="customer-info-container">
            <div className="customer-info-wrapper">
              <h3 className="checkout_headings">
                Payment Methods
              </h3>
              <button
            onClick={prevPage}
            className="checkout-back-button"
          >
            Back
          </button>
            </div>

            {openPaymentinfo ? (
              <div className="payments-items">
                <div className="customer-info1">
                  <h4>Payment Method</h4> 
                  <p>
                    {Object.values(paymentType).find(
                      (key) =>
                        key.value ===
                        values.payment_detail.payments[0].payment_type
                    )?.name || "N/A"}
                  </p>
                </div>
                <div className="customer-details">
                  <div className="customer-info1">
                    <h4>Amount</h4>
                    <p>{values.payment_detail.payments[0].amount}</p>
                  </div>
                  <div className="customer-info1">
                    <h4>Payment Date</h4>
                    <p>{userDetail.user.email}</p>
                  </div>
                  <div className="customer-info1">
                    <h4>Reference No</h4>
                    <p>
                      {values.reference_number
                        ? values.reference_number
                        : "N/A"}
                    </p>
                  </div>
                </div>
                <div className="customer-info1">
                  <h4>Memo</h4>
                  <p>
                    We understand that there may be exceptional
                    circumstances where cash or other forms of payment
                    may be necessary. In such cases, please obtain
                    prior approval from your department head and the
                    finance department.
                  </p>
                </div>
              </div>
            ) : (
              <div>
                <div className="button-container">
                  <Button
                    label={<span>Cash on delivery / On Account</span>}
                    onClick={() =>
                      handleClick(1, paymentType.COD_OA.value)
                    }
                    style={buttonStyle(1, "321px")}
                  />
                  <Button
                    label={<span>ACH Payment</span>}
                    onClick={() =>
                      handleClick(2, paymentType.ACH_PAYMENT.value)
                    }
                    style={buttonStyle(2, "206px")}
                  />
                  {payments?.["3"]?.["1"]?.public_key && (
                    <Button
                      label={<span>Credit / Debit Card</span>}
                      onClick={() =>
                        handleClick(
                          3,
                          paymentType.CREDIT_DEBIT_CARD.value
                        )
                      }
                      style={buttonStyle(3, "206px")}
                    />
                  )}
                </div>

                {activeTab === 1 && (
                  <div style={{ width: "100%" }}>
                    {/* <label style={{ marginBottom: "5px" }}>
              Memo
            </label>
            <Field
              className="textarea"
              component="textarea"
              name="memo"
             
              label={
                <span className="forms-labels">Memo</span>
              }
              maxLength={120}
            /> */}
                  </div>
                )}
                {activeTab === 2 && (
                  <div>
                    <Spin spinning={loadingBank}>
                      <div
                        className="customized-row"
                        style={{ marginBottom: "16px" }}
                      >
                        {!bankInfo?.account_number ? (
                          <div className="checkout-input-feilds">
                            <Button
                              label="Add ACH Payment"
                              onClick={openAchModal}
                              style={{
                                width: "217px",
                                height: "44px",
                                borderRadius: "32px",
                                marginTop: "16px",
                                background: "#5F4BB6",
                                color: "black",
                              }}
                            // className="export-btn"
                            />
                          </div>
                        ) : (
                          <div className="bank-details-container">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <h3>Bank Details</h3>
                              <div
                                style={{ display: "flex", gap: 4 }}
                              >
                                <img
                                  className="cursor-point"
                                  onClick={openViewAchModal}
                                  src={EyeIcon}
                                  alt=""
                                />
                                <img
                                  className="cursor-point"
                                  onClick={openAchModal}
                                  src={EditIcon}
                                  alt=""
                                />
                              </div>
                            </div>
                            <div style={{ display: "flex" }}>
                              <div
                                className="checkout-input-feilds"
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <label style={{ color: "#808080" }}>
                                  Account Number
                                </label>
                                <span className="masknumbers">
                                  {maskAccountNumber(
                                    bankInfo?.account_number
                                  )}
                                </span>
                              </div>
                              <div
                                className="checkout-input-feilds"
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <label style={{ color: "#808080" }}>
                                  Routing Number
                                </label>
                                <span className="masknumbers">
                                  {bankInfo?.routing_number}
                                </span>
                              </div>

                              <div
                                className="checkout-input-feilds"
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <label style={{ color: "#808080" }}>
                                  Account Title
                                </label>
                                <span className="masknumbers">
                                  {bankInfo?.account_name}
                                </span>
                              </div>

                              <div
                                className="checkout-input-feilds"
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <label style={{ color: "#808080" }}>
                                  Account Type
                                </label>
                                <span className="masknumbers">
                                  {bankInfo?.account_type}
                                </span>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </Spin>

                    <div className="">
                      <div
                        className="customized-row"
                        style={{ marginBottom: "16px" }}
                      >
                        <div className="checkout-input-feilds">
                          <Field
                            type="date"
                            name="pickup_delivery_date"
                            component={Input}
                            className="customized-input"
                            label={
                              <span className="forms-labels">
                                Payment Date
                                <span className="asterisk">*</span>
                              </span>
                            }
                          />
                        </div>

                        <div className="checkout-input-feilds">
                          <Field
                            type="text"
                            name="payment_detail.payments[0].reference_number"
                            component={Input}
                            className="customized-input"
                            placeholder="Refrence Number"
                            label={
                              <span className="forms-labels">
                                Reference Number
                              </span>
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {activeTab === 3 && (
                  <>
                    <div style={{ margin: "16px 0px" }}>
                      <label>Amount</label>
                      <div>
                        <input
                          className="customized-input .credit-debit-amount"
                          placeholder="Amount"
                          disabled={true}
                          value={saleTotal}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
          <div className="customer-info-container">
            <div className="customer-info-wrapper">
              <h3 className="checkout_headings">
                Order Notes
              </h3>
            </div>
            <div style={{ width: "100%" }}>
              <Field
                className="textarea"
                component="textarea"
                name="note"
                value={additionalNotes}
                onChange={({ target: { value } }) => {
                  setAdditionalNote(value)
                  setCheckoutShippingDetails((prev) => { return { ...prev, note: value } })
                }}
                maxLength={noteEcomLength}
                placeholder="Enter notes"
              />
              <p className="char-counter">
                {additionalNotes
                  ?.length || 0}
                /{noteEcomLength}
              </p>
            </div>
          </div>
          <div className="customer-info-container">
            <div className="checkout-cart-table">
              <table style={{ width: "100%" }}>
                <tr>
                  <th style={{ paddingRight: "16px" }}>Products</th>
                  <th style={{ paddingRight: "16px" }}>Quantity</th>
                  {uniqueTaxNames.map((taxName, index) => (
                    <th style={{ paddingRight: "16px" }} key={`header-${index}`}>
                      {taxName}
                    </th>
                  ))}


                  <th style={{ paddingRight: "16px" }}>Unit</th>
                  <th style={{ paddingRight: "16px" }}>Total</th>
                </tr>
                {calculation?.sale_order_details &&
                  calculation?.sale_order_details.length >= 1 &&
                  calculation?.sale_order_details.map((Item, key) => {
                    console.log("🚀 ~ Item:", Item)
                    const discountedPrice = getDiscountPrice(
                      Item.unit_price,
                      Item.discount
                    );
                    debugger
                    const finalProductPrice = +(getPriceCheckout(userDetail, Item, uniqueTaxValues) * (currencyRate || 1)
                    ).toFixed(2);
                    const finalDiscountedPrice = (discountedPrice * (currencyRate || 1)).toFixed(2);

                    discountedPrice != null
                      ? (cartTotalPrice +=
                        finalDiscountedPrice * Item.qty)
                      : (cartTotalPrice +=
                        finalProductPrice * Item.qty);
                    return (
                      <tr>
                        <td
                          style={{
                            width: "285px",
                            paddingRight: "16px",
                          }}
                        >
                          {Item?.product?.ecom_name || Item?.product?.name}
                        </td>
                        <td
                          style={{
                            paddingRight: "16px",
                          }}
                        >
                          {Item.qty}
                        </td>
                        {uniqueTaxNames.map((taxName, index) => {
                          const productTax = Item?.sale_order_detail_taxes?.find(
                            tax => tax?.tax?.name === taxName
                          );
                          return (
                            <td style={{ paddingRight: "16px" }} key={`amount-${key}-${index}`}>
                              {taxName ? (
                                <>
                                  {currency_symbol}{(productTax?.amount) || "0"}
                                </>
                              ) : null}
                            </td>
                          );
                        })}
                        <td style={{ paddingRight: "16px" }}>
                          {
                            Units[
                              Item?.unit
                            ].name
                          }
                        </td>
                        <td style={{ paddingRight: "16px" }}>
                          {discountedPrice !== null
                            ? currency_symbol +
                            (
                              (finalDiscountedPrice *
                              Item.qty) + getLineItemtaxeAmpount(key)
                            ).toFixed(2)
                            : currency_symbol +
                            (
                              (finalProductPrice *
                              Item.qty) + getLineItemtaxeAmpount(key)
                            ).toFixed(2)}
                        </td>
                      </tr>
                    );
                  })}
              </table>
            </div>
          </div>
        </>
      )}
    </Formik>
  )
}
PaymentMethod.propTypes = {
  Items: PropTypes.array,
  settings: PropTypes.object,
  sale: PropTypes.object,
  userDetail: PropTypes.object,
  deleteFromCart: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    Items: state.cartData,
    settings: state.settingData.ecom.basic,
    sale: state.saleData,
    userDetail: state.userData,
    adminSettings: state.settingData?.["admin-panel"] || { basic: { currency_symbol: "$" } },
  };
};


export default connect(mapStateToProps)(PaymentMethod);