import React from "react";

// For QRCode and BarCode
import QRCode from "react-qr-code";
import Barcode from "react-barcode";

import { Image } from "antd";
import { Popover } from "antd";

import { toUpper, replace, isEmpty, upperCase } from "lodash";

import {
  DISCOUNT_TYPE,
  ORDER_TYPE,
  // paymentType,
  SHIPPING_TYPE,
} from "../constant/Constants";
import {
  conditionallyRender,
  formatCurrency,
  formatDateTime,
  getValue,
} from "../constant/utils";

// Order's Invoice Status
import { INVOICE_STATUS } from "../constant/Constants";

// Paid Stamp
import PaidStamp from "../../components/paidStamp";
import { useSelector, shallowEqual } from "react-redux";

const styles = `
.invoice-body{
  font-size:15px !important;
  line-height:24px;
  font-family:'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
  background-color: white;
  padding: 0px;
  height: 100%;
}
.pdf-body{
  background-color: white;
  margin-right: 16px;
  margin-left: 16px;
}
.font-right{
  text-align:right;
}
.font-center{
  text-align:center
}
@media print {
  @page {
    margin-right: 3mm;
    margin-bottom: 10mm;
  }
}

.text {
  font-size: 13px;
}
.billing-col{
  margin-right: 10px;
}
.shipping-col{
  margin-left: 10px;
}

.hr{
  border-bottom: 1px solid black;
  margin-bottom: 5px !important;
}
.products{
  margin: 20px 0px;
}
.table-size{
  width:100%;
}
.table-product-serialNo-size{
  width:5%;
  text-align: center;
}
.heading{
  font-weight:bold;
  font-size: 14px;
  text-transform: uppercase;
}
.subheading {
  font-weight: bold;
  font-size: 13px;
}

.table-product-name-size{
  width:27%;
}
.table-product-sku-size{
  width:15%;
}
.table-product-barcode-col {
  width:25%;
}
.table-product-other-size{
  width:5%;
}
.table-product-image-size {
  width:7%
}
.table-product-sr-size{
  width:5%;
}
.table-product-qty-size{
  width:5%;
}
.table-product-price-size{
  width:8%;
}

.table-name-col{
  text-align:left;
}
.saved_amount_text{
  color: #000;
  font-size: 14px;
  margin-top: 10px;
}

.k-pdf-export p{
  font-size: 1.5rem !important;
  font-weight: bold;import PaidStamp from './../../components/paidStamp/index';

}
`;

export default function ({ order, currency }) {
  const {
    currency_symbol,
    tenant_invoice_logo,
    account_title,
    dist_addr,
    dist_cont_tel,
    dist_cont_email,
    msa,
    lic,
  } = currency;

  // PORTAL URL for QR code from redux state
  const { portal_url } = useSelector(
    (state) => state?.settingData,
    shallowEqual
  );

  // const getPaymentsType = (list) => {
  //   const arr = [];
  //   list.length &&
  //     list.map((el) => {
  //       Object.keys(paymentType).map((ell) => {
  //         if (el.payment_type == paymentType[ell].value) {
  //           arr.push(paymentType[ell].name);
  //         }
  //       });
  //     });
  //   return arr.toString();
  // };
  if (!isEmpty(order) && !!order?.invoice) {
    return (
      <html>
        <head>
          <meta charSet="utf-8" />
          <style dangerouslySetInnerHTML={{ __html: styles }} />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          ></meta>
        </head>
        <body className="invoice-body">
          <div className="container">
          <div className="d-flex justify-content-center pt-5 ">
            <h1>Sale Invoice</h1>
          </div>
          <table width="100%">
            <tbody>
              <tr>
                <td>
                  <div>
                    <div className="heading">{account_title}</div>
                    <div className="text">{dist_addr}</div>
                    <div className="text">{dist_cont_tel}</div>
                    <div className="text">{dist_cont_email}</div>
                    <div className="text">{msa}</div>
                    <div className="text">{lic}</div>
                  </div>
                </td>
                <td>
                  {tenant_invoice_logo ? (
                    <img
                      src={tenant_invoice_logo}
                      style={{
                        display: "block",
                        width: "auto",
                        height: "auto",
                        maxWidth: "300px",
                        maxHeight: "150px",
                        marginLeft: "auto",
                      }}
                      alt="Sale Invoice logo"
                    />
                  ) : (
                    "Sale Invoice logo"
                  )}
                  <div className="font-right text-center pt-2">
                    {/* QR Code will take Base URL from the existing environment and ID of the same order */}
                    <QRCode
                      style={{ height: "120px", maxWidth: "100%", width: "100%" }}
                      value={`${portal_url}/sale/invoice/${order?.peculiar_no}`}
                      viewBox={`0 0 350 350`}
                    />
                  </div>
                  <tr>
                    <td>
                      <br />
                    </td>
                    <td>
                      <br />
                    </td>
                  </tr>{" "}
                  <tr>
                    <td>
                      <br />
                    </td>
                    <td>
                      <br />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <br />
                    </td>
                    <td>
                      <br />
                    </td>
                  </tr>
                </td>
              </tr>
              <tr className="hr-line">
                <td colspan="2" class="hr-line"></td>
              </tr>
            </tbody>
          </table>
          <table className="w-100" width={"100%"}>
            <tbody>
              <tr>
                <td width="50%">
                  <div className="billing-col">
                    <table width="100%">
                      <tbody>
                        <tr>
                          <td>
                            <br />
                          </td>
                          <td>
                            <br />
                          </td>
                        </tr>
                        <tr>
                          <td className="subheading" width="40%">
                            Invoice/Order no #
                          </td>
                          <td className="text" width="60%">
                            {order.no}
                          </td>
                        </tr>

                        <tr>
                          <td width="40%" className="subheading">
                            Invoice Date
                          </td>
                          <td className="text" width="60%">
                            {formatDateTime(order.created_at)}
                          </td>
                        </tr>
                        <tr>
                          <td width="40%" className="subheading">
                            Order Type
                          </td>
                          <td className="text" width="60%">
                            {upperCase(getValue(ORDER_TYPE, order.order_type))}
                          </td>
                        </tr>
                        <tr>
                          <td width="40%" className="subheading">
                            Order By
                          </td>
                          <td width="60%" className="text">
                            {order?.created_by?.id == null
                              ? order?.customer?.business_name
                              : `${order?.created_by?.first_name}  
                          ${order?.created_by?.last_name}`}
                          </td>
                        </tr>
                        <tr>
                          <td width="40%" className="subheading">
                            Order Date
                          </td>
                          <td className="text" width="60%">
                            {formatDateTime(order.order_date)}
                          </td>
                        </tr>

                        <tr>
                          <td width="40%" className="subheading">
                            Payment Date
                          </td>
                          <td className="text" width="60%">
                            {formatDateTime(
                              order.invoice.payments[0]?.payment_date
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </td>
                <td width="50%" className="vertical-align">
                  <div className="billing-col">
                    <table width="100%">
                      <tbody>
                        <tr>
                          <td className="subheading" width="40%">
                            Business Name
                          </td>
                          <td className="text" width="60%">
                            {order.customer.business_name
                              ? order.customer.business_name
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <td width="40%" className="subheading">
                            Customer Name
                          </td>
                          <td className="text" width="60%">
                            {order.customer.name ? order.customer.name : "N/A"}
                          </td>
                        </tr>

                        <tr>
                          <td width="40%" className="subheading">
                            Phone
                          </td>
                          <td className="text" width="60%">
                            {order.customer.phone_no
                              ? order.customer.phone_no
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <td width="40%" className="subheading">
                            Email
                          </td>
                          <td className="text" width="60%">
                            {order.customer.email
                              ? order.customer.email
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <td width="40%" className="subheading">
                            Shipping Type
                          </td>
                          <td className="text" width="60%">
                            {replace(
                              toUpper(
                                getValue(SHIPPING_TYPE, order.shipping_type)
                              ),
                              ",",
                              ""
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <table className="w-100" width={"100%"}>
            <tbody>
              <tr>
                <td width="50%">
                  <div className="billing-col">
                    <table width="100%">
                      <tbody>
                        <tr>
                          <td>
                            <br />
                          </td>
                          <td>
                            <br />
                          </td>
                        </tr>
                        <tr>
                          <td width="40%" className="heading hr">
                            Bill To
                          </td>
                        </tr>
                        <tr>
                          <td className="subheading" width="40%">
                            Name
                          </td>
                          <td className="text" width="60%">
                            {conditionallyRender(
                              order?.billing_detail?.name,
                              "N/A"
                            )}
                          </td>
                        </tr>

                        <tr>
                          <td width="40%" className="subheading">
                            Phone No:
                          </td>
                          <td className="text" width="60%">
                            {conditionallyRender(
                              order?.billing_detail?.telephone_num,
                              "N/A"
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td width="40%" className="subheading">
                            Address
                          </td>
                          <td className="text" width="60%">
                            {!!order?.billing_detail?.address
                              ? `${order.billing_detail.address}, ${order.billing_detail.city}, ${order.billing_detail.state} ${order.billing_detail.zip_code}`
                              : "N/A"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </td>
                <td width="50%">
                  <div className="billing-col">
                    <table width="100%">
                      <tbody>
                        <tr>
                          <td>
                            <br />
                          </td>
                          <td>
                            <br />
                          </td>
                        </tr>
                        <tr>
                          <td width="40%" className="heading hr">
                            Ship To
                          </td>
                        </tr>
                        <tr>
                          <td className="subheading" width="40%">
                            Name
                          </td>
                          <td className="text" width="60%">
                            {conditionallyRender(
                              order?.shipping_detail?.name,
                              "N/A"
                            )}
                          </td>
                        </tr>

                        <tr>
                          <td width="40%" className="subheading">
                            Phone No:
                          </td>
                          <td className="text" width="60%">
                            {conditionallyRender(
                              order?.shipping_detail?.telephone_num,
                              "N/A"
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td width="40%" className="subheading">
                            Address
                          </td>
                          <td className="text" width="60%">
                            {!!order?.shipping_detail?.address
                              ? `${order.shipping_detail.address}, ${order.shipping_detail.city}, ${order.shipping_detail.state} ${order.shipping_detail.zip_code}`
                              : "N/A"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <hr />
          {!isEmpty(order.additional_attributes) ? (
            <>
              <div className="heading hr">Additional Information</div>
              <table>
                <tbody className="text">
                  {Object.keys(order.additional_attributes).map((attribute) => {
                    return (
                      <tr className="w-100">
                        <td className="w-75">
                          {" "}
                          <b>{attribute}</b>{" "}
                        </td>
                        <td>{order.additional_attributes[attribute]}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </>
          ) : (
            ""
          )}
          {[...order.sale_order_details].length > 0 ? (
            <div className="products">
              {/* <div className="heading hr">Products</div> */}
              <table className="items-table table-size table-striped">
                {/* Invoice Status Stamp will be appear on the basis of Invoice status */}
                {[INVOICE_STATUS.PAID.value, INVOICE_STATUS.OVER_PAID].includes(
                  order?.invoice?.status
                ) ? (
                  <PaidStamp />
                ) : null}
                <thead className="hr">
                  <tr>
                    <th className="table-product-sr-size">Sr. No</th>
                    <th className="table-product-image-size">Image</th>
                    <th className="table-product-name-size">
                      <div className="ml-4">Name</div>
                    </th>
                    <th className="table-product-sku-size">SKU</th>
                    <th className="table-product-other-size">UPC Barcode</th>
                    <th className="table-product-price-size ">Supply Price</th>
                    <th className="table-product-other-size ">Discount</th>
                    <th className="table-product-qty-size ">Qty</th>
                    <th className="table-product-other-size ">Extended</th>
                  </tr>
                </thead>
                <tbody className="text">
                  {order.sale_order_details.map((item, index) => (
                    <tr key={index}>
                      <td className="table-product-sr-size text pl-2">
                        {index + 1}
                      </td>
                      <td
                        className="table-product-image-size"
                        style={{ paddingTop: "9px", breakInside: "avoid" }}
                      >
                        <div className="symbol symbol-60 flex-shrink-0">
                          <Popover
                            zIndex={2000}
                            content={
                              <img
                                src={`${item.product.images[0]}`}
                                width={"120px"}
                                alt="Product Image"
                              />
                            }
                            title=""
                            trigger="hover"
                          >
                            <Image
                              src={item.product.images[0]}
                              style={{ width: "80px", height: "80px" }}
                            />
                          </Popover>
                        </div>
                      </td>
                      <td className="table-product-name-size text">
                        <div className="ml-4" style={{ width: "200px" }}>
                          {item?.product?.ecom_name || item?.product?.name}
                          {item.product.sub_products?.length > 0 && (
                            <div>
                              {item.product.sub_products.map((pro, index) => (
                                <small className="ls-1">{`${pro.child_name} x ${pro.child_qty * item.qty
                                  } ${item.product.sub_products.length - 1 != index
                                    ? ","
                                    : ""
                                  } `}</small>
                              ))}
                            </div>
                          )}
                        </div>
                      </td>
                      <td className="table-product-sku-size text">
                        {item.product.sku}
                      </td>
                      <td className="table-product-barcode-col">
                        {/* Barcode takes a value props with same upc number of the product and generate barcode for this */}
                        <Barcode
                          value={
                            item.product.upc != null
                              ? `${item.product.upc}`
                              : ""
                          }
                          height={25}
                          background={index % 2 == 0 ? "#ecf0f3" : "#fff"}
                        />
                      </td>
                      <td className="table-product-price-size text">
                        {currency_symbol}
                        {formatCurrency(item.unit_price)}
                      </td>
                      <td className="table-product-price-size text">
                        {item.discount_type == DISCOUNT_TYPE.FIXED
                          ? currency_symbol
                          : ""}
                        {item.discount != null ? item.discount : 0}
                        {item.discount_type == DISCOUNT_TYPE.PERCENTAGE
                          ? "%"
                          : ""}
                      </td>
                      <td className="table-product-qty-size text">
                        {item.qty}
                      </td>
                      <td className="table-product-other-size text">
                        {item.discount_type == DISCOUNT_TYPE.FIXED
                          ? formatCurrency(
                            (item.unit_price - item.discount || 0) * item.qty
                          )
                          : formatCurrency(
                            item.unit_price * item.qty -
                            (item.unit_price * item.qty * item.discount ||
                              0) /
                            100
                          )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            ""
          )}
          <div className="hr" />
          <div className="d-flex justify-content-end" style={{display:"flex", justifyContent:"end"}}>
            <table width="33%">
              <tbody>
                {order.invoice?.sub_total ? (
                  <tr>
                    <td className="subheading">Sub Total</td>
                    <td className="font-right">
                      {currency_symbol}
                      {formatCurrency(order.invoice.sub_total)}
                    </td>
                  </tr>
                ) : (
                  ""
                )}

                {order.discount && order.discount > 0 ? (
                  <tr>
                    <td className="subheading">Other Discount</td>
                    <td className="font-right">
                      {order.discount_type == DISCOUNT_TYPE.FIXED
                        ? currency_symbol
                        : ""}
                      {order.discount}
                      {order.discount_type == DISCOUNT_TYPE.PERCENTAGE
                        ? "%"
                        : ""}
                    </td>
                  </tr>
                ) : (
                  ""
                )}

                {order.invoice.total_discount > 0 ? (
                  <tr>
                    <td className="subheading">Discount Amount</td>
                    <td className="font-right">
                      {currency_symbol}
                      {formatCurrency(order.invoice.total_discount)}
                    </td>
                  </tr>
                ) : (
                  ""
                )}

                {order.delivery_charges > 0 ? (
                  <tr>
                    <td className="subheading">Delivery Charges</td>
                    <td className="font-right">
                      {currency_symbol}
                      {formatCurrency(order.delivery_charges)}
                    </td>
                  </tr>
                ) : (
                  ""
                )}
                {order.tax > 0 ? (
                  <tr>
                    <td className="subheading">Tax</td>
                    <td className="font-right">
                      {order.tax_type == 2
                        ? `${currency_symbol}${order.tax}`
                        : `${order.tax}%`}
                    </td>
                  </tr>
                ) : (
                  ""
                )}
                {order.invoice?.total_amount ? (
                  <tr>
                    <td className="subheading">Total Amount</td>
                    <td className="font-right">
                      {currency_symbol}
                      {formatCurrency(order.invoice.total_amount)}
                    </td>
                  </tr>
                ) : (
                  ""
                )}

                <tr>
                  <td>
                    <br />
                  </td>
                  <td>
                    <br />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <table width="100%" >
            <tbody>
              <tr className="footer-quote">
                <td className="subheading">
                  Thank you for your business.
                  <br />
                  Powered by KommerceHub.
                </td>

                <td className="font-right">Signature: ____________________</td>
              </tr>
            </tbody>
          </table>
          <table width="100%">
            <tbody>
              <tr>
                <td className="font-center">
                  {order?.saved_amount > 0 && order?.customer?.tier_id ?
                    <p className="saved_amount_text">
                      You have saved <span style={{ color: "green" }}>${formatCurrency(order?.saved_amount)}</span> on this
                      sale order. Thanks for shopping and being a loyal customer!
                    </p>

                    : order?.saved_amount > 0 ?
                      <p className="saved_amount_text">
                        You Saved <span style={{ color: "red" }}>${formatCurrency(order?.saved_amount)}</span>
                      </p> : ""
                  }
                </td>
              </tr>
            </tbody>
          </table>
          </div>
        </body>
      </html>
    );
  } else {
    return null;
  }
}
