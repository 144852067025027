import React from "react";
import { Modal, Button, Space } from "antd";
import { navigateToGoogle } from "../../pages/constant/utils";
import AgeAboveButton from "../../../src/assets/images/ageVerificationabove.png";
import AgeBelowButton from "../../../src/assets/images/ageVerificationbelow.png";
import "./style.css";

const AgeConfirmationModal = ({ ageConfirmationCheck, onOk }) => {
  return (
    <Modal
      // title="This Website offers tobacco products and is strictly for adult use only.Before you proceed further, please confirm that you are 21 years of age or older."
      centered
      className="ageRestrictionModal"
      open={ageConfirmationCheck}
      onOk={onOk}
      bodyStyle={ModalBodyStyle}
      size="lg"
      footer={null}
      closable={false}
    >
      <div
        className="main-modal"
      >
        <div
          className="modal-heading"
        >
          Age Verification
        </div>
        <div
          className="modal-text"
        >
          This Website offers tobacco products and is strictly for adult use
          only.Before you proceed further, please confirm that you are 21 years
          of age or older.
        </div>
        <div style={{ display: "flex" }}>
        <button  className="age-verify-button cursor-pointer" onClick={onOk} >
        I am 21 or older
        </button>
          {/* <Button type="primary" onClick={onOk}>
              I am 21 or older - Enter
            </Button> */}
          {/* <Button type="primary" danger onClick={navigateToGoogle}>
              I am under 21 - Exit
            </Button> */}
          <button
            className="age-verify-button  cursor-pointer"
            onClick={navigateToGoogle}
          >
            I am under 21
            </button>
        </div>
      </div>
    </Modal>
  );
};
export default AgeConfirmationModal;

// Style Components
const ModalBodyStyle = {
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
  // minWidth: "707px",
  // minHeight: "422px",
};
