import React from "react";
import { ErrorMessage } from "formik";
import { Select as AntSelect } from "antd";
import { getPath, TextError } from "../../pages/constant/utils";
import { COUNTRIES } from "./../../pages/constant/Constants";

const getFieldCSSClasses = (touched, errors, suffix) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid");
  }
  if (touched && !errors && !suffix) {
    // classes.push("is-valid ");
  }
  if (touched && !errors && suffix) {
    classes.push("input-field-color");
  }

  return classes.join(" ");
};

export function Select({
  field,
  form: { errors, touched },
  label,
  suffix,
  withFeedbackLabel = true, 
  customFeedbackLabel,
  options,
  type = "text",
  autoFocus,
  tooltipContent,
  customStyle = false,
  showOther,
  ...props
}) {
  const propClass = props?.className || "";

  return (
    <>
      {label && <label> {label}</label>}
      <div style={{ position: "relative" }}>
        <AntSelect
        showSearch
        optionFilterProp="children"
          {...field}
          className={`selector-field ${propClass} ${getFieldCSSClasses(
            getPath(field.name, touched),
            getPath(field.name, errors),
            suffix
          )} `}
          {...field}
          {...props}
        >
          <AntSelect.Option value="" className="check-it">
            Please Select
          </AntSelect.Option>
          {!!options
            ? options.length > 0 &&
              options.map((option) => (
                <AntSelect.Option
                  key={option || option.value}
                  value={option || option.value}
                >
                  {option || option.type}
                </AntSelect.Option>
              ))
            : Object.keys(COUNTRIES).map((country) => (
                <AntSelect.Option key={country} value={country}>
                  {country}
                </AntSelect.Option>
              ))}
          {!showOther && (
            <AntSelect.Option value="other">Other</AntSelect.Option>
          )}
        </AntSelect>
        <ErrorMessage name={field.name} component={TextError} />
      </div>
    </>
  );
}
