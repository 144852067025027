import PropTypes from "prop-types";
import React, { Fragment, useState, useEffect } from "react";
import HeaderOne from "../wrappers/header/HeaderOne";
import Footer from "../wrappers/footer/Footer";
import FooterSkeleton from "./FooterSkeleton";

const LayoutOne = ({
  children,
  headerContainerClass,
  headerTop,
  headerPaddingClass,
  headerPositionClass,
}) => {
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);
  
  return (
    <Fragment>
      <HeaderOne
        layout={headerContainerClass}
        top={headerTop}
        headerPaddingClass={headerPaddingClass}
        headerPositionClass={headerPositionClass}
      />
      <div className="extra-row"></div>
      {children}
      {isLoading && <FooterSkeleton />}
    {!isLoading && (
      <Footer />
    )}
    </Fragment>
  );
};

LayoutOne.propTypes = {
  children: PropTypes.any,
  headerContainerClass: PropTypes.string,
  headerPaddingClass: PropTypes.string,
  headerPositionClass: PropTypes.string,
  headerTop: PropTypes.string,
};

export default LayoutOne;
