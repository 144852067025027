import {
  GET_ALL_CUSTOM_PAGES_URL,
  GET_SINGLE_CUSTOM_PAGES_URL,
  GET_CITY_URL,
  GET_STATE_URL,
  PRESIGNED_URL,
  ORDER_URL_EXPORT,
  GET_ALL_COUNTIES,
} from "../../constants/config/config.local";
import {
  GET_ALL_BILLING_STATES,
  GET_ALL_BILLING_CITIES,
  GET_ALL_CITIES_SUCCESS,
  SET_LOADING,
  GET_ALL_CUSTOM_PAGES,
  GET_SINGLE_CUSTOM_PAGE,
  EXPORT_PDF_CSV,
  EXPORT_PDF_CSV_LOADING,
  EXPORT_PDF_CSV_STOPPED,
  GET_ALL_COUNTIES_SUCCESS,
  GET_ALL_STATES_SUCCESS
} from "../constant_types";
import axios from "axios";
import { CELERY_PROGRESS_STATUS, EXPORT_FILE_TYPE } from "../../pages/constant/Constants";
import {notification} from "antd";
import { backgroundTaskHandler } from "../../pages/constant/utils";

const fetchStatesSuccess = (states) => ({
  type: GET_ALL_STATES_SUCCESS,
  payload: states,
});
const fetchCountiesSuccess = (counties) => ({
  type: GET_ALL_COUNTIES_SUCCESS,
  payload: counties,
});
const fetchCitiesSuccess = (cities) => ({
  type: GET_ALL_CITIES_SUCCESS,
  payload: cities,
});
const fetchBillingStatesSuccess = (states) => ({
  type: GET_ALL_BILLING_STATES,
  payload: states,
});

const fetchBillingCitiesSuccess = (cities) => ({
  type: GET_ALL_BILLING_CITIES,
  payload: cities,
});


const fetchAllCustomPagesSuccess =(pages)=>({
  type:GET_ALL_CUSTOM_PAGES, 
  payload:pages
})

const fetchSingleCustomPageSuccess =(page)=>({
  type:GET_SINGLE_CUSTOM_PAGE, 
  payload:page
})

const ExportStarted =()=>({
  type:EXPORT_PDF_CSV_LOADING, 
})

const ExportStoped =(page)=>({
  type:EXPORT_PDF_CSV_STOPPED, 
  payload:page
})

const ExportStatus =(page)=>({
  type:EXPORT_PDF_CSV, 
  payload:page
})

const setLoaderSuccess = (loading) => ({
  type: SET_LOADING,
  payload: loading,
});

// Get All Countries
export const fetchStates = (params) => {
  return (dispatch) => {
    dispatch(setLoaderSuccess(true));
    axios
      .get(`${GET_STATE_URL}`, { params: params })
      .then((res) => {
        dispatch(fetchStatesSuccess(res.data.entity));
        dispatch(setLoaderSuccess(false));
      })
      .catch((err) => {
        console.log("Error =>", err);
      });
  };
};

// Get All Counties
export const fetchCounties = (params) => {
  return (dispatch) => {
    dispatch(setLoaderSuccess(true));
    axios
      .get(`${GET_ALL_COUNTIES}`, { params: params })
      .then((res) => {
        dispatch(fetchCountiesSuccess(res.data.entity));
        dispatch(setLoaderSuccess(false));
      })
      .catch((err) => {
        console.log("Error =>", err);
      });
  };
};

// Get All Cities
export const fetchCities = (params) => {
  return (dispatch) => {
    dispatch(setLoaderSuccess(true));
    axios
      .get(`${GET_CITY_URL}`, { params: params })
      .then((res) => {
        dispatch(fetchCitiesSuccess(res.data.entity));
        dispatch(setLoaderSuccess(false));
      })
      .catch((err) => { });
  };
};
// Get All Countries for billing
export const fetchBillingStates = (params) => {
  return (dispatch) => {
    dispatch(setLoaderSuccess(true));
    axios
      .get(`${GET_STATE_URL}`, { params: params })
      .then((res) => {
        dispatch(fetchBillingStatesSuccess(res.data.entity));
        dispatch(setLoaderSuccess(false));
      })
      .catch((err) => { });
  };
};

// Get All Cities for Billing
export const fetchBillingCities = (params) => {
  return (dispatch) => {
    dispatch(setLoaderSuccess(true));
    axios
      .get(`${GET_CITY_URL}`, { params: params })
      .then((res) => {
        dispatch(fetchBillingCitiesSuccess(res.data.entity));
        dispatch(setLoaderSuccess(false));
      })
      .catch((err) => { });
  };
};


// Get Pre-signed URL from backend 
export function getPresignedUrl(key) {
  key = key.replaceAll(" ", "-");
  return axios.post(PRESIGNED_URL, [{ key: key }]);
}
// sale order export
export function saleOrderExport(payload) {
  console.log("🚀 ~ saleOrderExport ~ payload:", payload)
  return axios.post(`${ORDER_URL_EXPORT}`, { ...payload, page_size: -1 },
  );
}

// Upload File to Cloud
export function uploadFileToCloud(payload) {
  return fetch(payload.url, {
    method: "PUT",
    body: payload.file,
  });
}


// Get All Custom Pages
export const fetchCustomPages = (params) => {
  return (dispatch) => {
    dispatch(setLoaderSuccess(true));
    axios
      .get(`${GET_ALL_CUSTOM_PAGES_URL}`)
      .then((res) => {
        dispatch(fetchAllCustomPagesSuccess(res.data));
        dispatch(setLoaderSuccess(false));
      })
      .catch((err) => {
        console.log("Error =>", err);
      });
  };
};

// Get Single Custom Pag

export const fetchSingleCustomPage = (params, history) => {
  return (dispatch) => {
    dispatch(setLoaderSuccess(true));
    axios
      .get(`${GET_SINGLE_CUSTOM_PAGES_URL}/${params}`)
      .then((res) => {
        dispatch(fetchSingleCustomPageSuccess(res.data));
        dispatch(setLoaderSuccess(false));
      })
      .catch((err) => {
        console.log("Error =>", err);
        history.push("/not-found")
        dispatch(setLoaderSuccess(false));
      });
  };
};

export const exportPDF =({params={},keys=[], headers=[],reportName="report123",exportKey="id", exportType=EXPORT_FILE_TYPE.PDF}) => {
  return async (dispatch) => {
    dispatch(setLoaderSuccess(true));
      if (exportType.export_type === EXPORT_FILE_TYPE.PDF) {
        notification.info({ message: "You cannot export PDF with more than 12 columns" })
        return
      }
      try {
        const response = await saleOrderExport(
          {
            ...params,
            export_type:exportType, 
            keys: keys,
            headers: headers,
            report_name:reportName
          }
        );
        localStorage.setItem(exportKey, response.data.id);
        backgroundTaskHandler(exportKey, (arg)=>{dispatch(ExportStatus(arg))} );
        dispatch(ExportStarted());
        dispatch(ExportStatus({url:"", status:CELERY_PROGRESS_STATUS[1].slug}));
      } catch (err) {
        dispatch(ExportStoped());
        err.response.data.errors.map((error) => notification.error({ message: error }));
      }
  };
  }
