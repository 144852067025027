import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import MetaTags from "react-meta-tags";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { connect } from "react-redux";
import { Table, Tabs, Pagination } from "antd";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import { formatDateTime, formatCurrency } from "../../pages/constant/utils";
import { PAYMENT_CATEGORY, PAYMENT_TYPE } from "../../pages/constant/Constants";
import axios from "axios";
import { ORDER_URL, TRANSACTION_URL } from "../../constants/config";

const CustomerLedgerTable = ({ setting }) => {
  const history = useHistory();

  const [orderList, setOrderList] = useState({
    loading: false,
    data: [],
    totalCount: null,
  });
  const [transList, setTransList] = useState({
    loading: false,
    data: [],
    totalCount: null,
  });
  const { TabPane } = Tabs;

  // Pagination Controller

  // Initial Value of Page Size
  const pageSize = 20;
  // State to manage Current Page
  const [currentOrderListingPage, setCurrentOrderListingPage] = useState(1);
  const [currentTransactionListingPage, setCurrentTransactionListingPage] =
    useState(1);
  // params
  const params = { page_size: pageSize, page: 1, invoice_status: 2 };
  // current state page
  const startOrderTransactionIndex =
    (currentTransactionListingPage - 1) * pageSize;
  const startOrderListingIndex = (currentOrderListingPage - 1) * pageSize;

  //Current Page from the given Data

  //Handel Page Change for Sale Order Table
  const handleOrderListingPageChange = (pageNumber) => {
    setCurrentOrderListingPage(pageNumber);
    getOrderListing(params);
  };

  const handleTransactionListingPageChange = (pageNumber) => {
    setCurrentTransactionListingPage(pageNumber);
    getTransactionListing({
      page_size: pageSize,
      page: pageNumber,
    });
  };

  const getOrderListing = (params) => {
    // order
    setOrderList((prev) => {
      return { ...prev, loading: true };
    });
    axios
      .get(`${ORDER_URL}/list2`, {
        params: { ...params },
      })
      .then((res) => {
        setOrderList((prev) => {
          return {
            ...prev,
            data: res.data.entities,
            loading: false,
            totalCount: res.data.totalCount,
          };
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getOrderListing(params);
  }, [params]);
  useEffect(() => {
    getTransactionListing({ page_size: pageSize, page: 1 });
  }, []);
  const getTransactionListing = (params) => {
    // Transaction
    setTransList((prev) => {
      return { ...prev, loading: true };
    });
    axios
      .get(`${TRANSACTION_URL}/list`, { params })
      .then((res) => {
        setTransList((prev) => {
          return {
            ...prev,
            data: res.data.entities,
            loading: false,
            totalCount: res.data.totalCount,
          };
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const TranscationHistoryColumns = [
    {
      title: "Sr. no",
      render: (_, row, index) => {
        return (
          <>
            <div
              style={{
                textAlign: "center",
                padding: "5px 10px 5px 10px",
                maxWidth: "fit-content",
              }}
            >
              {/* As we are managing indexing of the table on the basis of current result index so that's why I wrote this logic for Sr. No */}
              {Number(transList?.totalCount - index) -
                startOrderTransactionIndex}
            </div>
          </>
        );
      },
    },
    {
      title: "Order No / Date",
      dataField: "invoice_no",
      key: "invoice_no",
      render: (_, row, index) => {
        return (
          <>
            {row.sale_order_no}
            <br></br>
            {row.payment_date && formatDateTime(row.created_at)}
          </>
        );
      },
    },
    {
      dataField: "payment_type",
      title: "Payment Type",
      render: (_, row, index) => (
        <>
          {Object.keys(PAYMENT_TYPE).map((type, i) => {
            if (PAYMENT_TYPE[type].value === row.payment_type) {
              return PAYMENT_TYPE[type].type;
            }
          })}
        </>
      ),
    },
    {
      dataField: "amount",
      title: "Paid Amount",
      render: (_, row, index) => {
        return (
          <>
            <div
              style={{
                backgroundColor: "#ef3c6c",
                borderRadius: "20px",
                textAlign: "center",
                padding: "5px 10px 5px 10px",
                color: "white",
                maxWidth: "fit-content",
              }}
            >
              <b>
                {/* {currencySymbol}&nbsp; */}
                {`${setting.currency_symbol} ${formatCurrency(
                  row.amount
                ).toLocaleString()}`}
              </b>
            </div>
          </>
        );
      },
    },
    {
      dataField: "payment_date",
      title: "Payment Date",
      render: (_, row, index) => {
        if (row.payment_date != undefined) {
          return <>{formatDateTime(row.payment_date)}</>;
        }
      },
    },
    {
      dataField: "category",
      text: "Payment Category",
      formatter: (cellContent, row) => (
        <>
          {Object.keys(PAYMENT_CATEGORY).map((category) => {
            if (PAYMENT_CATEGORY[category].value === cellContent) {
              return PAYMENT_CATEGORY[category].type;
            }
          })}
        </>
      ),
    },
  ];

  const SaleHistoryColumns = [
    {
      title: "Sr. no",
      render: (_, row, index) => {
        return (
          <>
            <div
              style={{
                textAlign: "center",
                padding: "5px 10px 5px 10px",
                maxWidth: "fit-content",
              }}
            >
              {/* As we are managing indexing of the table on the basis of current result index so that's why I wrote this logic for Sr. No */}
              {Number(orderList?.totalCount - index) - startOrderListingIndex}
            </div>
          </>
        );
      },
    },
    {
      title: "Order No/ Date",
      dataIndex: "no",
      key: "no",
      render: (_, row, index) => (
        <>
          {row.no}
          <br></br>
          {formatDateTime(row.created_at)}
        </>
      ),
    },
    {
      title: "Invoice Amount",
      dataIndex: "total_price",
      key: "total_price",
      render: (_, row, index) => {
        const { total_price } = row;
        return (
          <div
            style={{
              backgroundColor: "#ef3c6c",
              borderRadius: "20px",
              textAlign: "center",
              padding: "5px 10px 5px 10px",
              color: "white",
              maxWidth: "fit-content",
            }}
          >
            <b>{`${setting.currency_symbol} ${formatCurrency(total_price)}`}</b>
          </div>
        );
      },
    },

    {
      title: "Amount Paid",
      key: "amount_paid",
      dataIndex: "amount_paid",
      render: (_, row, index) => {
        let amountPaid;
        try {
          const { total_price, remaining_amount } = row;
          if (
            typeof total_price != "number" ||
            typeof remaining_amount != "number"
          ) {
            amountPaid = "N/A";
          } else {
            amountPaid = isNaN(total_price - remaining_amount)
              ? "N/A"
              : total_price - remaining_amount;
          }
        } catch (error) {
          amountPaid = "N/A";
        }
        return (
          <div
            style={{
              backgroundColor: "#ef3c6c",
              borderRadius: "20px",
              textAlign: "center",
              padding: "5px 10px 5px 10px",
              color: "white",
              maxWidth: "fit-content",
            }}
          >
            <b>{`${setting.currency_symbol} ${formatCurrency(amountPaid)}`}</b>
          </div>
        );
      },
    },
    {
      title: "Remaining Amount",
      dataIndex: "remaining_amount",
      key: "remaining_amount",
      render: (_, row, index) => {
        const { remaining_amount } = row;

        return (
          <div
            style={{
              backgroundColor: "#ef3c6c",
              borderRadius: "20px",
              textAlign: "center",
              padding: "5px 10px 5px 10px",
              color: "white",
              maxWidth: "fit-content",
            }}
          >
            <b>{`${setting.currency_symbol} ${formatCurrency(
              remaining_amount
            )}`}</b>
          </div>
        );
      },
    },
  ];

  return (
    <Fragment>
      <MetaTags>
        <title>{setting?.ecom_title} | Order History</title>
        <meta
          name="description"
          content={`Order History page of ${setting?.ecom_title} eCommerce template.`}
        />
      </MetaTags>
      <BreadcrumbsItem to={process.env.PUBLIC_URL + "/"}>Home</BreadcrumbsItem>
      <BreadcrumbsItem to={process.env.PUBLIC_URL + history.location.pathname}>
        Ledger
      </BreadcrumbsItem>
      <LayoutOne headerTop="visible">
        {/* breadcrumb */}
        <Breadcrumb />
        <div className="cart-main-area pt-20 pb-100">
          <div className="container-fluid">
            <Tabs defaultActiveKey={1} size={"small"} onTabClick={(rest) => {}}>
              <TabPane tab="Pending invoices" key="1">
                <Fragment>
                  <Table
                    columns={SaleHistoryColumns}
                    dataSource={orderList?.data}
                    loading={orderList?.loading}
                    pagination={false}
                  />
                  <Pagination
                    current={currentOrderListingPage}
                    defaultCurrent={currentOrderListingPage}
                    total={orderList?.totalCount}
                    pageSize={pageSize}
                    className="mt-2 text-right"
                    onChange={handleOrderListingPageChange}
                  />
                </Fragment>
              </TabPane>
              <TabPane tab="Transaction history" key="2">
                <Fragment>
                  <Table
                    columns={TranscationHistoryColumns}
                    dataSource={transList?.data}
                    loading={transList.loading}
                    pagination={false}
                  />
                  <Pagination
                    current={currentTransactionListingPage}
                    defaultCurrent={currentTransactionListingPage}
                    total={transList?.totalCount}
                    pageSize={pageSize}
                    className="mt-2 text-right"
                    onChange={handleTransactionListingPageChange}
                  />
                </Fragment>
              </TabPane>
            </Tabs>
          </div>
        </div>
      </LayoutOne>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    cartItems: state.cartData,
    setting: state.settingData.ecom.basic,
    userDetail: state.userData,
    orderDetail: state.saleData.orderEntities,
    singleOrder: state.saleData.singleOrder,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteAllFromCart: (addToast) => {
      console.log("deleteAllFromCart no defination");
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerLedgerTable);
