import PropTypes from "prop-types";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import MetaTags from "react-meta-tags";
import { connect, useDispatch } from "react-redux";
import {
  addToCart,
  bulkAddToCart,
  decreaseQuantity,
  deleteFromCart,
  deleteAllFromCart,
} from "../../redux/actions/cartActions";
import LayoutOne from "../../layouts/LayoutOne";
import { defaultImageUrl, defaultLogo, searchFilter } from "../constant/Constants";
import { getPriceRelatedToCustomer, refModal, tobaccoEligibilityMessage } from "../constant/utils";
import { fetchProductsForQuick } from "../../redux/actions/productActions";
import { Image, Input } from "antd";
import { notification, Popover, Tooltip, Spin } from "antd";
import { JreRenderList } from "../../redux/actions/checkoutActions";
import confirm from "antd/lib/modal/confirm";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Player from "../../components/Player";
import { useToasts } from "react-toast-notifications";
import { addonBefore } from "../../components/utils/AdsOnBefore";
import { getUnitNameByValue } from "../../constants";
import { isNull } from "lodash";
import Upcoming from "./../../assets/images/Upcoming.png";
import Promotions from "../../components/common/Promotions";
import PromotionsPrice from "../../components/common/PromotionPrice";
import PromotionsQTY from "../../components/common/PromotionQTY";

var _new_selected = {};
let debounceTimeout = null;

const QuickOrder = ({
  channel_id,
  userDetail,
  cartData,
  userData,
  settings,
  adminSettings,
  prev_cart = [],
  location,
}) => {
  const { pathname } = location;
  const {
    price_display_for_unsecure_user,
    price_display_for_secure_user,
    skip_eligibility_check,
    dist_cont_email,
    ecom_title,
    stock_display_for_secure_user,
    stock_display_for_unsecure_user,
  } = settings || {};
  const { currency_symbol } = adminSettings?.basic || {}
  const { delivery_charges, tax, tax_type } = adminSettings?.pos || {}
  const [loader, setLoader] = useState(false);
  const InputRef = useRef(0);
  const [scanQty, setScanQty] = useState(1);
  const [searchInput, setSearchInput] = useState("");
  const [scanOption, setScanOption] = useState(true);
  const dispatch = useDispatch();
  const [localProducts, setLocalProducts] = useState([]);
  const { addToast } = useToasts();
  let cartTotalPrice = 0;
  const [playAudio] = Player();
  let modalRef = useRef(null);
  const licensePath = () => history.push('/profile/my-account')
  const [cartItems, setCartItems] = useState(() => {
    debugger
    return [
      ...prev_cart,
      ...cartData.map((item) => {
        return {
          ...item,
          enterQuantity: item.quantity,
          orderQty: item.quantity,
        };
      }),
    ];
  });
  console.log("🚀 ~ const[cartItems,setCartItems]=useState ~ cartItems:", cartItems)

  const history = useHistory();
  var timeId;

  var _handleChangeAsync = (products) => {
    clearTimeout(timeId);
    timeId = setTimeout(() => {
      for (let i = 0; i < products.length; i++) {
        _new_selected[products[i].id] = i;
      }
    }, 311);
  };

  const handleCartItems = (product, enterQuantity, scanOption, isScan) => {
    if (!product.is_tobacco ||
      settings.skip_eligibility_check || userData.user.tobacco_eligibility) {

      const intQuantity = parseInt(enterQuantity) || 0;
      let selectedProduct = { ...product };
      let _products = [...cartItems];
      let _product = cartItems.findIndex((pro) => pro.id == product.id);
      //eslint-disable-next-line
      if (intQuantity == 0 && _product == -1) {
        return;
      }
      if (intQuantity == 0) {
        _products.splice(_product, 1);
        setCartItems(_products);
        _handleChangeAsync(_products);

        return;
      }
      if (!product.back_order_ecom && !product?.saleable_qty) {
        return notification.error({ message: "Product out of Stock" });
      }
      if (
        !product.back_order_ecom &&
        intQuantity + ((isScan && product?.orderQty) || 0) >
        product.saleable_qty
      ) {
        playAudio("warning");
        return notification.error({ message: "Product out of Stock" });
      }
      if (_product < 0) {
        const isBackOrderAllow =
          selectedProduct?.back_order_ecom &&
          (isNull(selectedProduct.ps_allowed_qty) ||
            intQuantity + (product?.orderQty || 0) <= product.ps_allowed_qty);
        const allowedQty = product.back_order_ecom
          ? (product.ps_allowed_qty || 0) > product.saleable_qty
            ? product.ps_allowed_qty
            : product.saleable_qty
          : product.saleable_qty;
        if (
          intQuantity + (product?.orderQty || 0) > allowedQty &&
          !isBackOrderAllow
        ) {
          const errorMessage = `Sorry,the Max allowed quantity per sale is exceeded.`;
          addToast(errorMessage, {
            appearance: "error",
            autoDismiss: true,
          });
          return;
        }
        selectedProduct.enterQuantity = intQuantity;
        selectedProduct.orderQty = intQuantity;
        selectedProduct.subTotal = intQuantity * product.price;
        setCartItems((cartItems) => {
          let _prod = cartItems.findIndex((pro) => pro.id == product.id);
          if (_prod >= 0 && isScan) {
            let _products = [...cartItems];
            _products.splice(_product, 1, {
              ...selectedProduct,
              quantity:
                Number(intQuantity) + Number(_products[_product]?.quantity) ||
                1,
              subTotal:
                Number(intQuantity) + Number(_products[_product]?.quantity) ||
                1 * product.cost,
            });
            return _products;
          }
          return [selectedProduct, ...cartItems];
        });

        scanOption && playAudio();

        _handleChangeAsync([selectedProduct, ...cartItems]);
      } else {
        if (scanOption) {
          const isBackOrderAllow =
            selectedProduct?.back_order_ecom &&
            (isNull(selectedProduct.ps_allowed_qty) ||
              intQuantity + product.orderQty <= product.ps_allowed_qty);
          const allowedQty = product.back_order_ecom
            ? (product.ps_allowed_qty || 0) > product.saleable_qty
              ? product.ps_allowed_qty
              : product.saleable_qty
            : product.saleable_qty;
          if (
            intQuantity + (product?.orderQty || 0) > allowedQty &&
            !isBackOrderAllow
          ) {
            const errorMessage = `Sorry,the Max allowed quantity per sale is exceeded.`;
            addToast(errorMessage, {
              appearance: "error",
              autoDismiss: true,
            });
            return;
          }
          if (
            selectedProduct.saleable_qty >=
            selectedProduct.enterQuantity + intQuantity ||
            isBackOrderAllow
          ) {
            const newQuantity =
              intQuantity + product.orderQty < product.saleable_qty ||
                isBackOrderAllow
                ? intQuantity + product.orderQty || product.enterQuantity
                : product.orderQty;

            const updated_product = {
              ...selectedProduct,
              enterQuantity: newQuantity,
              orderQty: newQuantity,
              subTotal: (product.orderQty + intQuantity) * product.price,
            };
            _products.splice(_product, 1, updated_product);
            if (
              (product.back_order_ecom &&
                intQuantity + product.orderQty <= product.ps_allowed_qty) ||
              intQuantity + product.orderQty <= product.ps_allowed_qty
            ) {
              playAudio();
              notification.success({
                message: " Quantity Updated",
              });
            }
          }
        }
        if (!isScan) {
          _products.splice(_product, 1, {
            ...selectedProduct,
            enterQuantity: intQuantity,
            quantity: intQuantity,
            orderQty: intQuantity,
            subTotal: intQuantity * (product?.price || product?.selected_unit_price?.price),
          });
        }
        setCartItems(_products);
        _handleChangeAsync(_products);
      }
    } else {
      refModal(modalRef, licensePath)
    }

    // I worte this logic to make refelection for updated product in the Selected Product list
    const element = document.querySelector(`.selected_cart_item_${product.id}`);
    if (element) {
      element.style.backgroundColor = "yellow";
      setTimeout(() => {
        element.style.backgroundColor = "white";
      }, 100);
    }

  };

  const debounce = (func, delay) => {
    return function (...args) {
      clearTimeout(debounceTimeout);
      debounceTimeout = setTimeout(() => func.apply(this, args), delay);
    };
  };

  const handleSearchProduct = (e, channel_id) => {
    setLoader(true);
    fetchProductsForQuick(
      {
        ...searchFilter,
        channel_id,
        search_key: e.target.value,
        page: 1,
        page_size: 50,
        default_variant: "",
      },
    ).then((res) => {
      setLocalProducts(res.data.entities);
      setLoader(false);
    });
  };

  // Wrap handleSearchProduct with debounce
  const debouncedSearchProduct = debounce(handleSearchProduct, 1000); // 300ms delay

  const barcodeHandleSearch = (value, channel_id) => {
    fetchProductsForQuick(
      {
        ...searchFilter,
        channel_id,
        search_key: value,
        page: 1,
        page_size: 100,
        scan_mode: 1,
        default_variant: "",
      },
    )
      .then((res) => {
        const { totalCount, entities } = res.data;
        if (!entities[0].eligibility_check?.is_eligible) {
          notification.error({ message: `Sale of this  ${entities[0]?.name} is not allowed due to ${entities[0]?.eligibility_check.reasons}. Please upload the license in the 'Profile' section to make a purchase.` })
        } else {
          totalCount > 0 &&
            handleCartItems(entities[0], scanQty, scanOption, true);
        }
        if (!totalCount) {
          notification.error({
            message: "Product Not Avaliable",
          });
          playAudio("error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (!searchInput) {
      let e = {
        target: {
          value: "",
        },
      };
      handleSearchProduct(e, channel_id);
    }
  }, [searchInput]);

  const handleQtyChange = (e, product, qty = undefined) => {
    const newQty = qty ?? +e.target.value?.trimStart();
    if (newQty < 0) {
      newQty ?? (e.target.value = 0);
    } else {
      if (product?.ps_allowed_qty && newQty > product.ps_allowed_qty) {
        const errorMessage = `Sorry,the Max allowed quantity per sale is ‘MAx Qty’.`;
        addToast(errorMessage, {
          appearance: "error",
          autoDismiss: true,
        });
        return;
      }

      if (!(!product.is_tobacco ||
        settings.skip_eligibility_check || userData.user.tobacco_eligibility)) {
          refModal(modalRef, licensePath)
        return
      }

      const isValid =
        newQty >= 0 &&
        !(newQty ?? e.target.value)
          .toString()
          .split("")
          .find((e) => e === ".");

      if (!isValid) return;

      if (
        product?.back_order_ecom ||
        (product.saleable_qty != null && product.saleable_qty >= newQty)
      ) {
        handleCartItems(product, newQty, false, false);
      }
    }
  };
  const cartGrandTotal = (productTotal) => {
    let total = productTotal;
    total =
      productTotal +
      delivery_charges || 0 +
      (tax_type === 2 || !tax
        ? tax || 0
        : (tax * productTotal) / 100 + productTotal);
    return total;
  };
  return (
    <Fragment>
      <MetaTags>
        <title>{ecom_title} | Cart</title>
        <meta
          name="description"
          content={`Cart page of ${ecom_title} eCommerce template.`}
        />
      </MetaTags>

      {/* <BreadcrumbsItem to={process.env.PUBLIC_URL + "/home"}>
        Home
      </BreadcrumbsItem>
      <BreadcrumbsItem to={process.env.PUBLIC_URL + pathname}>
        Quick Order
      </BreadcrumbsItem> */}
      <LayoutOne headerTop="visible">
        {/* breadcrumb */}
        {/* <Breadcrumb /> */}
        <div className="quick-order-main-area pt-15 pb-100">
          <div
            className="row"
            style={{ marginTop: "10px", marginBottom: "20px" }}
          >
            <div className="quick-order-search-filter-class">
              <label>Search by Name, UPC</label>
              <Input
                ref={InputRef}
                className="search-product"
                placeholder="Search by Name, UPC"
                addonBefore={addonBefore}
                value={searchInput}
                onChange={(e) => {
                  setSearchInput(e.target.value);
                  debouncedSearchProduct(e);
                }}
                onKeyDown={(event) => {
                  if (event.key === "Enter" && !!event.target.value) {
                    if (scanOption) {
                      event.stopPropagation();
                      let value = event.currentTarget.value;
                      event.currentTarget.value = "";
                      event.target.value = "";
                      setSearchInput("");
                      InputRef && (InputRef.current.input.value = "");
                      let exist = false;
                      if (!!cartItems?.length) {
                        exist = [...cartItems].find(
                          (e) => e.sku == value || e.upc == value
                        );
                        if (exist) {
                          handleCartItems(exist, scanQty, scanOption, true);
                        } else {
                          barcodeHandleSearch(value, channel_id);
                        }
                      } else {
                        barcodeHandleSearch(value, channel_id);
                      }
                      dispatch(JreRenderList());
                    }
                  }
                }}
              />
            </div>

            <div className="quick-order-scan-qty-class">
              <label>Scan Qty</label>
              <input
                type="number"
                value={scanQty}
                onChange={(e) => setScanQty(e.target.value)}
                defaultValue={1}
                placeholder="Scan Qty"
              />
            </div>
          </div>

          {/* Products */}
          <Spin spinning={loader}>
            <div className="row" style={{ gap: "24px" }}>
              <div className="product-section">
                <div className="product-table" style={{ width: "880px" }}>
                  <table width="100%">
                    <thead>
                      <tr
                        style={{
                          borderBottom: "1px solid #B3B3B3",
                        }}
                      >
                        <th className="image-column cart-table-label">Image</th>
                        <th className="product-name-column cart-table-label">Product name</th>
                        <th className="upc cart-table-label">UPC</th>
                        <th className="unit cart-table-label">Unit</th>
                        <th className="qty cart-table-label">Quantity</th>
                        {((userData && price_display_for_secure_user) ||
                          (!userData && price_display_for_unsecure_user)) && (
                            <th className="price cart-table-label">Price</th>
                          )}
                      </tr>
                    </thead>
                    <tbody>
                      {localProducts &&
                        !loader &&
                        !!localProducts?.length &&
                        localProducts.map((product, key) => (
                          <tr
                            style={{
                              borderBottom: "1px solid #B3B3B3",
                            }}
                          >
                            <td style={{ width: "6%", height: "70px" }}>
                              <div className="image-column">
                                <Popover
                                  title=""
                                  trigger="hover"
                                >
                                  <Image
                                    src={
                                      (product?.images?.[0] ?? product?.product?.images?.[0]) === defaultImageUrl
                                        ? (settings.logo === defaultLogo ? Upcoming : settings.logo)
                                        : ((product?.images?.[0] ?? product?.product?.images?.[0]) ? process.env.PUBLIC_URL + (product?.images?.[0] ?? product?.product?.images?.[0]) : "")
                                    }
                                    width={"70px"}
                                    height={"59px"} 
                                    style={
                                      product?.images?.[0] === defaultImageUrl
                                        ? { height: "70px", objectFit: "contain" }
                                        : {objectFit: "contain",}
                                    }
                                  />
                                </Popover>
                              </div>
                            </td>
                            <td>
                              <div>
                                <div style={{display:"flex"}}>
                                <div className="pro-name">
                                  {product?.ecom_name || product?.name}
                                </div>
                                  <Promotions product={product} classMain={"promotion-quick"} classText={"promotion-text-quick"}/>
                                  </div>
                                {((!userDetail?.user &&
                                  stock_display_for_unsecure_user) ||
                                  (userDetail?.user &&
                                    stock_display_for_secure_user)) && (
                                    <div
                                      style={{
                                        display: "flex",
                                        width: "102px",
                                        height: "16px",
                                        padding: "7px 10px",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        gap: "7px",
                                        borderRadius: "24px",
                                        background: product?.back_order_ecom ? "#FDFAEB" : product?.saleable_qty <= 0 ? "#FFE7E5" : "#EFEDF8",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "flex",
                                          width: "80px",
                                          height: "16px",
                                          flexDirection: "column",
                                          justifyContent: "center",
                                          color: product?.back_order_ecom ? "#EFD138" : product?.saleable_qty <= 0 ? "#FB1100" : "#5F4BB6",
                                          textAlign: "center",
                                          fontFamily: "Montserrat",
                                          fontSize: "12px",
                                          fontStyle: "normal",
                                          fontWeight: "500",
                                          lineHeight: "18px",
                                        }}
                                      >
                                        {
                                          product?.back_order_ecom
                                            ? "Low Stock"
                                            : product?.saleable_qty <= 0
                                              ? "Out of Stock"
                                              : `Qty: ${product.saleable_qty || 0}`
                                        }

                                      </span>
                                    </div>
                                  )}
                              </div>
                            </td>
                            <td className="upc">
                              {product.upc}
                            </td>
                            <td className="qo-unit">
                              <div
                                style={{
                                  display: "flex",
                                  height: "32px",
                                  width: "120px",
                                  padding: "10px 16px",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  alignSelf: "stretch",
                                  borderRadius: "32px",
                                  background: "#F7F7F7",
                                }}
                              >
                                {getUnitNameByValue(
                                  product.selected_unit_price.default_unit
                                )}
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/media/icons/arrows.svg"
                                  }
                                />
                              </div>
                              <div></div>
                            </td>
                            <td style={{ width: "2%", position:"relative",verticalAlign:"inherit" }}>
                              <div>
                                <div
                                  style={{
                                    display: "flex",
                                    width: "127px",
                                    height: "32px",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    borderRadius: "32px",
                                    border: "1px solid #F2F2F2",
                                    background: "#F7F7F7",
                                  }}
                                >
                                  <img
                                    className="cursor-pointer"
                                    src={
                                      `${process.env.PUBLIC_URL}` +
                                      "/media/icons/minus-icon.svg"
                                    }
                                    onClick={() => {
                                      handleQtyChange(
                                        {},
                                        product,
                                        +(
                                          cartItems.find(
                                            (el) => el.id == product.id
                                          )?.orderQty || 0
                                        ) - 1
                                      )
                                    }
                                    }
                                  />
                                  <input
                                    style={{
                                      background: "#F7F7F7",
                                      border: "none",
                                      marginLeft: "10px",
                                    }}
                                    value={
                                      cartItems.find((el) => el.id == product.id)
                                        ?.orderQty || 0
                                    }
                                    onChange={(e) => handleQtyChange(e, product)}
                                  />
                                  <img
                                    className="cursor-pointer"
                                    src={
                                      `${process.env.PUBLIC_URL}` +
                                      "/media/icons/plus-icon.svg"
                                    }
                                    onClick={() => {
                                      if (product?.eligibility_check && !product?.eligibility_check?.is_eligible) {
                                        notification.error({ message: `Sale of this ${product?.name} is not allowed due to ${product?.eligibility_check?.reasons}. Please upload the license in the 'Profile' section to make a purchase.` })
                                      } else {
                                        handleQtyChange(
                                          {},
                                          product,
                                          +(
                                            cartItems.find(
                                              (el) => el.id == product.id
                                            )?.orderQty || 0
                                          ) + 1
                                        )
                                      }
                                    }
                                    }
                                  />
                                </div>
                                  <PromotionsQTY selectedVariant={product} quantity={false}/>
                              </div>
                            </td>
                            {((userData && price_display_for_secure_user) ||
                              (!userData && price_display_for_unsecure_user)) && (
                                <td className="price" style={{width:"100%", display:"flex", flexDirection:"column"}}>
                                  <PromotionsPrice product={product} currency={currency_symbol} regularprice={true} />
                                </td>
                              )}
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="quick-cart">
                <div
                  style={{
                    color: "#1A1A1A",
                    fontFamily: "Montserrat",
                    fontSize: "30px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "38px",
                    marginTop: "24px",
                    marginLeft: "24px",
                    marginBottom: "24px",
                  }}
                >
                  Cart
                </div>

                {/* Cart */}
                <div>
                  <div className="cart-added-product">
                    <table>
                      <tr
                        style={{
                          borderBottom: "1px solid #B3B3B3",
                        }}
                      >
                        <td
                          style={{
                            height: "71px",
                            color: "#808080",
                          }}
                        >
                          Products Name
                        </td>
                        <td
                          style={{
                            width: "10%",
                            height: "32px",
                            textAlign: "center",
                            color: "#808080",
                          }}
                        >
                          Unit
                        </td>
                        <td
                          style={{
                            width: "10%",
                            height: "32px",
                            textAlign: "center",
                            color: "#808080",
                          }}
                        >
                          Qty
                        </td>
                        <td style={{ width: "10%" }}></td>
                      </tr>
                      {cartItems.map((product) => (
                        <tr
                          style={{
                            borderBottom: "1px solid #B3B3B3",
                          }}
                        >
                          <td
                            style={{
                              overflow: "hidden",
                              color: "#1A1A1A",
                              textOverflow: "ellipsis",
                              width: "188px",
                              height: "51px",
                            }}
                          >
                            <div
                              className="pro-name-cart"
                            >
                              <span>
                                <Tooltip title={product?.ecom_name || product?.name}>
                                  <span>{product?.ecom_name || product?.name}</span>
                                </Tooltip>
                              </span>
                            </div>
                          </td>
                          <td>
                            <div
                              style={{
                                display: "flex",
                                height: "32px",
                                padding: "10px 16px",
                                justifyContent: "space-between",
                                width: "111px",
                                alignItems: "center",
                                alignSelf: "stretch",
                                borderRadius: "32px",
                                background: "#F7F7F7",
                              }}
                            >
                              {getUnitNameByValue(
                                product.selected_unit_price.unit
                              )}{" "}
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/media/icons/arrows.svg"
                                }
                              />
                            </div>
                          </td>
                          <td>
                            <div
                              style={{
                                display: "flex",
                                width: "120px",
                                height: "32px",
                                justifyContent: "space-between",
                                alignItems: "center",
                                borderRadius: "32px",
                                border: "1px solid #F2F2F2",
                                background: "#F7F7F7",
                              }}
                            >
                              <img
                                className="cursor-pointer"
                                src={
                                  `${process.env.PUBLIC_URL}` +
                                  "/media/icons/minus-icon.svg"
                                }
                                onClick={() =>
                                  handleQtyChange(
                                    {},
                                    product,
                                    +(
                                      cartItems.find((el) => el.id == product.id)
                                        ?.orderQty || 0
                                    ) - 1
                                  )
                                }
                              />
                              <input
                                style={{
                                  background: "#F7F7F7",
                                  border: "none",
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                }}
                                value={product?.orderQty || 0}
                                onChange={(e) => handleQtyChange(e, product)}
                              />
                              <img
                                className="cursor-pointer"
                                src={
                                  `${process.env.PUBLIC_URL}` +
                                  "/media/icons/plus-icon.svg"
                                }
                                onClick={() =>
                                  handleQtyChange(
                                    {},
                                    product,
                                    +(
                                      cartItems.find((el) => el.id == product.id)
                                        ?.orderQty || 0
                                    ) + 1
                                  )
                                }
                              />
                            </div>
                          </td>
                          <td
                            style={{ textAlign: "right", cursor:"pointer" }}
                            class="del-item"
                            onClick={() => {
                              handleQtyChange({}, product, 0);

                              deleteFromCart(product, addToast);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                            >
                              <path
                                d="M12.6364 3.90909V3.18182C12.6364 1.97683 11.6595 1 10.4545 1H7.54545C6.34047 1 5.36364 1.97683 5.36364 3.18182V3.90909M1 3.90909H17M11.1818 8.27273V11.9091M6.81818 8.27273V11.9091M2.45455 3.90909H15.5455L15.0636 13.5452C14.9669 15.4805 13.3695 17 11.4318 17H6.56818C4.63045 17 3.03312 15.4805 2.93635 13.5452L2.45455 3.90909Z"
                                stroke="var(--style-primary-color)"
                                stroke-width="2"
                                stroke-linecap="round"
                              />
                            </svg>
                          </td>
                        </tr>
                      ))}
                    </table>
                  </div>
                </div>

                {/* Cart ends */}

                <div className="checkout-button">
                  <button
                    disabled={cartItems.length == 0}
                    onClick={() => {
                      dispatch(
                        bulkAddToCart(cartItems, addToast, () => {
                          history.push(`/checkout`);
                        })
                      );
                    }}
                    style={{
                      display: "flex",
                      width: "90%",
                      padding: "10px",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",

                      borderRadius: "32px",
                      background: "var(--style-primary-color)",
                      color: "#FFF",
                      textAlign: "center",
                      fontFamily: "Montserrat",
                      fontSize: "18px",
                      fontStyle: "normal",
                      fontWeight: "500",
                      lineHeight: "28px",
                      border: "none",
                      margin: "24px 0px 24px 24px",
                    }}
                  >
                    Proceed To Checkout
                  </button>
                </div>

                {/* <div
                style={{
                  color: "#1A1A1A",
                  fontFamily: "Montserrat",
                  fontSize: "30px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "38px",
                  marginTop: "24px",
                  marginLeft: "24px",
                  marginBottom: "24px",
                }}
              >
                <p style={{ marginBottom: "0px", color: "#808080" }}>
                  Pre-Tax Total
                </p>
                {cartItems.map((product) => (
                <p style={{ color: "#1A1A1A" }}>{currency_symbol + (product?.orderQty * product.selected_unit_price.price)}</p>
                ))}
              </div> */}
              </div>
            </div>

            <div className="row" style={{ display: "none" }}>
              <div className="col-lg-7 col-md-7">
                <div className="products">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Product Name</th>
                        <th>Images</th>
                        <th>SKU</th>
                        <th>UPC</th>
                        <th style={{ width: "120px" }}>Saleable Qty</th>
                        <th>Quantity</th>
                        {((userData && price_display_for_secure_user) ||
                          (!userData && price_display_for_unsecure_user)) && (
                            <th>Price</th>
                          )}
                      </tr>
                    </thead>
                    {(localProducts &&
                      !loader &&
                      localProducts.length &&
                      localProducts.map((product, key) => (
                        <tr
                          style={{
                            backgroundColor:
                              product.saleable_qty === 0 &&
                                product?.back_order_ecom
                                ? "rgb(239 213 237)"
                                : product.saleable_qty === 0 &&
                                  !product?.back_order_ecom
                                  ? "#ccc"
                                  : "#fff",
                          }}
                        >
                          <td className="py-1">{key + 1}</td>
                          <td className="py-1">{product?.ecom_name || product?.name}</td>
                          <td className="py-1">
                            <div>
                              <Popover
                                zIndex={2000}
                                content={
                                  <img
                                    src={`${product?.images
                                      ? product?.images[0]
                                      : product?.product?.images[0]
                                      }`} 
                                    width={"120px"}
                                    alt="Product Image"
                                  />
                                }
                                title=""
                                trigger="hover"
                              >
                                <Image
                                  src={
                                    product?.images
                                      ? product?.images[0]
                                      : product?.product?.images[0] || ""
                                  }
                                  width={"40px"}
                                />
                              </Popover>
                            </div>
                          </td>
                          <td className="py-1">{product.sku}</td>
                          <td className="py-1">{product.upc}</td>
                          <td style={{ width: "120px" }} className="py-1">
                            {product?.saleable_qty || 0}
                          </td>
                          <td className="py-1">
                            <input
                              name="enterQuantity"
                              style={{ height: "30px", width: "90px" }}
                              type="number"
                              placeholder={"Quantity"}
                              value={
                                cartItems.find((el) => el.id == product.id)
                                  ?.orderQty || 0
                              }
                              min="0"
                              onBlur={(e) => {
                                if (
                                  !product.back_order_ecom &&
                                  Number(e.target.value) > product.saleable_qty
                                ) {
                                  e.target.value = product.saleable_qty;
                                }
                              }}
                              onChange={(e) => {
                                if (e.target.value < 0) {
                                  e.target.value = 0;
                                } else {
                                  if (
                                    product?.ps_allowed_qty &&
                                    Number(e.target.value) >
                                    product.ps_allowed_qty
                                  ) {
                                    const errorMessage = `Sorry,the Max allowed quantity per sale is ‘MAx Qty’.`;
                                    addToast(errorMessage, {
                                      appearance: "error",
                                      autoDismiss: true,
                                    });
                                    return;
                                  }

                                  if (!(!product.is_tobacco ||
                                    settings.skip_eligibility_check || userData.user.tobacco_eligibility)
                                  ) {
                                    modalRef.current = confirm({
                                      icon: <ExclamationCircleOutlined />,
                                      placement: "bottomRight",
                                      content: tobaccoEligibilityMessage(licensePath, modalRef),
                                      onOk() { },
                                      onCancel() { },
                                      cancelText: "Close",
                                    });
                                  }

                                  const isValid =
                                    Number(e.target.value) >= 0 &&
                                    !e.target.value
                                      .toString()
                                      .split("")
                                      .find((e) => e === ".");

                                  if (!isValid) return;

                                  if (
                                    product?.back_order_ecom ||
                                    (product.saleable_qty != null &&
                                      product.saleable_qty >=
                                      Number(e.target.value))
                                  ) {
                                    handleCartItems(
                                      product,
                                      e.target.value.trimStart(),
                                      false,
                                      false
                                    );
                                  }
                                }
                              }}
                            />
                          </td>
                          {((userData && price_display_for_secure_user) ||
                            (!userData && price_display_for_unsecure_user)) && (
                              <td className="py-1">
                                {`${currency_symbol}${getPriceRelatedToCustomer(
                                  userDetail,
                                  product
                                )}`}
                              </td>
                            )}
                        </tr>
                      ))) || (
                        <td colSpan={7}>
                          <p style={{ color: "#A0A0A0" }} className="text-center">
                            {!loader ? "No Product to show!" : "Loading..."}
                          </p>
                        </td>
                      )}
                  </table>
                </div>
              </div>
              <div className="col-lg-5 col-md-5">
                <div
                  style={{ height: "100vh", position: "sticky", top: 0 }}
                  className="card"
                >
                  <p className="card-header h5 font-weight-20">
                    Selected Products
                  </p>
                  <div
                    className="card-body"
                    style={{
                      maxHeight: "750px",
                      overflowY: "scroll",
                      padding: "5px",
                    }}
                  >
                    <table className="w-100">
                      <thead>
                        <tr>
                          <th style={{ width: "30px" }}>Sr#</th>
                          <th style={{ width: "250px" }}>Product Name</th>
                          <th style={{ width: "70px" }}>SKU / UPC</th>
                          <th style={{ width: "30px" }}>
                            <p
                              style={{ fontWeight: "bold" }}
                              className="float-right"
                            >
                              Quantity
                            </p>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {cartItems.map((product, key) => {
                          return (
                            <tr className={`selected_cart_item_${product.id}`}>
                              <td style={{ width: "30px" }}>{key + 1}</td>
                              <td style={{ width: "250px" }}>{product?.ecom_name || product?.name}</td>
                              <td style={{ width: "70px" }}>
                                {product.sku} / {product.upc}
                              </td>
                              <td style={{ width: "30px" }}>
                                <input
                                  name="enterQuantity"
                                  style={{ height: "30px", width: "90px" }}
                                  type="number"
                                  placeholder={"Quantity"}
                                  value={product.orderQty || 0}
                                  min="0"
                                  onBlur={(e) => {
                                    if (
                                      !product.back_order_ecom &&
                                      Number(e.target.value) >
                                      product.saleable_qty
                                    ) {
                                      e.target.value = product.saleable_qty;
                                    }
                                  }}
                                  onChange={(e) => handleQtyChange(e, product)}
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                      <tr></tr>
                    </table>

                    <div className="mt-3"></div>
                  </div>
                  <div className="card-footer">
                    <button
                      disabled={cartItems.length == 0}
                      onClick={() => {
                        dispatch(bulkAddToCart(cartItems, channel_id));
                        history.push(`/checkout`);
                      }}
                      className="btn btn-primary float-right"
                    >
                      Proceed to checkout
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Spin>
        </div>
      </LayoutOne>
    </Fragment>
  );
};

QuickOrder.propTypes = {
  addToCart: PropTypes.func,
  productDetail: PropTypes.object,
  saleDetail: PropTypes.object,
  userDetail: PropTypes.object,
  cartData: PropTypes.object,
  decreaseQuantity: PropTypes.func,
  deleteAllFromCart: PropTypes.func,
  deleteFromCart: PropTypes.func,
  settingData: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    settings: state.settingData.ecom.basic,
    userDetail: state.userData,
    productDetail: state.productData,
    saleDetail: state.saleData,
    cartData: state.cartData,
    userData: state.userData,
    channel_id: state?.settingData?.channel_id,
    adminSettings: state.settingData?.["admin-panel"] || {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (item, addToast, quantityCount) => {
      dispatch(addToCart(item, addToast, quantityCount));
    },
    decreaseQuantity: (item, addToast) => {
      dispatch(decreaseQuantity(item, addToast));
    },
    deleteFromCart: (item, addToast) => {
      dispatch(deleteFromCart(item, addToast));
    },
    deleteAllFromCart: (addToast) => {
      dispatch(deleteAllFromCart(addToast));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(QuickOrder);
