import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import axios from "axios";
import { save } from "redux-localstorage-simple";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import { Provider } from "react-redux";
import { fetchSettings } from "./redux/actions/storeSettingsActions";
import { fetchCategoryTree } from "./redux/actions/categoryActions";
import rootReducer from "./redux/reducers/rootReducer";
import App from "./App";
import "./assets/scss/style.scss";
import * as serviceWorker from "./serviceWorker";
import { composeWithDevTools } from "redux-devtools-extension";
import * as _redux from "./redux";
import { setUser } from "./redux/actions/loginActions";
import { fetchEcomTemplate } from "./redux/actions/ecomTemplateActions";

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk, save()))
);

_redux.setupAxios(axios, store);

// fetch products from json file
store.dispatch(setUser());
store.dispatch(
  fetchCategoryTree({
    page_size: -1,
    sort_by: "name:asc",
  })
);
store.dispatch(fetchSettings());
store.dispatch(fetchEcomTemplate());

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
