// Load App Libs
import { Stage } from "./../index";

// [APP BASICS]
export const CURRENT_STAGE = Stage.LOCAL;

// [API Endspoints]
// export const API_BASE_URL = "https://34lahore.pkhub.app/api";

// export const API_BASE_URL = "http://bc7c-116-206-166-46.ngrok.io/api/dev";
// process.env.REACT_APP_ENV
export let API_BASE_URL = "/api";

if (process.env.NODE_ENV === "development") {
  API_BASE_URL = "http://tenant1.localhost:5000/api";
  // API_BASE_URL = "https://tenant18.dev.ekhub.biz/api";
}
// console.log("build base", API_BASE_URL, process.env);

//Catalog
export const BRAND_URL = `${API_BASE_URL}/catalog/brands`;
export const CATEGORY_URL = `${API_BASE_URL}/catalog/categories`;
export const CATEGORY_EXPORT = `${API_BASE_URL}/catalog/categories/export`;
export const PRODUCT_URL = `${API_BASE_URL}/catalog/products`;
export const SUPPLIER_URL = `${API_BASE_URL}/inventory/suppliers`;
export const TAG_URL = `${API_BASE_URL}/catalog/tags`;
export const ATTRIBUTE_URL = `${API_BASE_URL}/catalog/attributes`;

//Inventory
export const CHANNEL_URL = `${API_BASE_URL}/inventory/channel`;
export const WAREHOUSE_URL = `${API_BASE_URL}/inventory/warehouse`;
export const STORAGES_URL = `${API_BASE_URL}/inventory/storage`;
export const STOREFRONT_URL = `${API_BASE_URL}/inventory/storefront`;
export const PURCHASE_ORDER_URL = `${API_BASE_URL}/inventory/purchase/orders`;
export const PURCHASE_RECEIVE_URL = `${API_BASE_URL}/inventory/purchase/receives`;
export const STOCK_URL = `${API_BASE_URL}/inventory/stocks`;
export const STOCK_TRANSFER_URL = `${API_BASE_URL}/inventory/stocks/transfer`;
export const PURCHASE_RETURN_URL = `${API_BASE_URL}/inventory/purchase-returns`;

//Sale
export const CUSTOMERS_URL = `${API_BASE_URL}/sale/customers`;
export const COUPONS_URL = `${API_BASE_URL}/sale/coupon/validate`;
export const PRODUCTS_URL = `${API_BASE_URL}/catalog/products`;
export const ORDER_URL = `${API_BASE_URL}/sale/order`;
export const ORDER_URL_EXPORT = `${API_BASE_URL}/sale/order/list2/export`;
export const Customer_LEDGER_URL = `${API_BASE_URL}/sale/ledgers`;
export const TRANSACTION_URL = `${API_BASE_URL}/sale/payment`;
export const PAYMENT_URL = `${API_BASE_URL}/sale/payment`;
export const ORDER_RETURN_URL = `${API_BASE_URL}/sale/return`;
export const REGISTER_URL = `${API_BASE_URL}/auth/register`;
export const REQUEST_PASSWORD_URL = `${API_BASE_URL}/auth/forgot-password`;
export const CUSTOMER_REPORT = `${API_BASE_URL}/sale/customers/export`;

// Cart
export const ADD_TO_CART_URL = `${API_BASE_URL}/core/customers/cart`;

//Core
export const SO_MAIL_INVOICE = `${API_BASE_URL}/core/utils/email/so_invoice`;
export const PR_MAIL_INVOICE = `${API_BASE_URL}/core/utils/email/pr_invoice`;
export const USER_URL = `${API_BASE_URL}/core/user`;
export const REFRESH_TOKEN = `${API_BASE_URL}/api/dev/core/user/authenticate/refresh`;
export const PRESIGNED_URL = `${API_BASE_URL}/core/utils/presigned_urls`;
export const GET_STATE_URL = `${API_BASE_URL}/core/utils/state`;
export const GET_ALL_COUNTIES = `${API_BASE_URL}/core/utils/county`;
export const GET_CITY_URL = `${API_BASE_URL}/core/utils/city`;
export const CHECK_EMAIL = `${API_BASE_URL}/core/customers/email_check`;
export const CONSENT_TENANT= `${API_BASE_URL}/core/consent`;
export const CONSENT_CUSTOMER= `${API_BASE_URL}/core/consent/customer`;

export const GET_ALL_CUSTOM_PAGES_URL = `${API_BASE_URL}/core/custom_pages/list`;
export const GET_SINGLE_CUSTOM_PAGES_URL = `${API_BASE_URL}/core/custom_pages`;
// export const GET_ALL_CUSTOM_PAGES_URL = `https://d7df-111-88-240-106.ngrok.io/api/dev/core/custom_pages/list`

//Core => Auth
export const LOGIN_URL = `${API_BASE_URL}/core/customers/authenticate`;
export const REGISTER_CUSTOMER_URL = `${API_BASE_URL}/core/customers/sign-up/form`;
export const SALE_AGENT_ASSIGN_CUSTOMER_URL = `${API_BASE_URL}/sale/customers/list2`;
export const SALE_AGENT_LOGIN_URL_VIA_CUSTOMER = `${API_BASE_URL}/core/customers/switch`;

// export const ME_URL = `${API_BASE_URL}/core/settings_v2/`;
export const ME_URL = `${API_BASE_URL}/core/customers/me`;
export const UPDATE_CUSTOMER_URL = `${API_BASE_URL}/core/customers/customer_update`;
export const REFRESH_TOKEN_URL = `${API_BASE_URL}/core/customers/authenticate/refresh`;
export const RESET_PASSWORD_URL = `${API_BASE_URL}/core/customers/reset_password`;
export const CHANGE_PASSWORD = `${API_BASE_URL}/core/customers/change_password`;

//Report
export const MSA_REPORT_URL = `${API_BASE_URL}/report/msa`;
export const BASIC_DASHBOARD_STATS = `${API_BASE_URL}/report/dashboard/basic_stats`;
export const DATA_DASHBOARD_STATS = `${API_BASE_URL}/report/dashboard/stats`;
export const DATA_DASHBOARD_STOCK_ALERT = `${API_BASE_URL}/report/dashboard/stocks`;
export const CHANNELS_STOCK_REPORT = `${API_BASE_URL}/inventory/stocks/export`;
export const PRODUCT_REPORT = `${API_BASE_URL}/catalog/products/export`;

// Basic Settings
//export const BASIC_SETTINGS_URL = `${API_BASE_URL}/core/store_setting`;
export const SETTINGS_URL = `${API_BASE_URL}/core/setting`;
export const S3_BUCKET_URL = `https://khub-app.s3-accelerate.amazonaws.com/`;
// export const CURRENCY_SETTINGS_URL = `${API_BASE_URL}/core/settings/basic`
export const CURRENCY_SETTINGS_URL = `${API_BASE_URL}/core/settings_v2/`;

// Background task status
export const BACKGROUND_TASK_STATUS = `${API_BASE_URL}/core/utils/task`;

// customer query
export const QUERY_URL = `${API_BASE_URL}/core/customer_query`;

// Ecom template Url
export const ECOM_TEMPLATE_URL = `${API_BASE_URL}/core/template/website`;

// payment

export const ECOM_ACH_ONBOARD_URL = `${API_BASE_URL}/sale/payment/ach/onboard`;
export const BANK_DETAIL_URL = `${API_BASE_URL}/sale/payment/ach/bank`;
export const ECOM_ACH_ACCOUNT_DETAIL = `${API_BASE_URL}/sale/payment/ach`;
// export const ECOM_ACH_ONBOARD_URL = `${API_BASE_URL}/sale/payment/grailpay/onboard`;
// export const BANK_DETAIL_URL = `${API_BASE_URL}/sale/payment/grailpay/bank`;
