import { FETCH_TAG_LIST } from "../actions/tagActions";

const initState = {
  tags: [],
  totalCount: null,
  pageSize: null,
};

const tagReducer = (state = initState, action) => {
  if (action.type === FETCH_TAG_LIST) {
    return {
      ...state,
      tags: action.payload.entities,
      pageSize: action.payload.pageSize,
      totalCount: action.payload.totalCount,
    };
  }

  return state;
};

export default tagReducer;
