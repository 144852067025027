import React from "react";
import { formatCurrency } from "../../pages/constant/utils";

export default function PromotionsPrice({ product, currency, sidebarFilter,anotherclass, regularprice }) {
  return (
    <>
    {regularprice && 
      <span
          className={
            sidebarFilter === "inActive"
              ? "card-old-price-label"
              : sidebarFilter
              ? "card-old-price-label-inactive"
              : {anotherclass}
          }
        >
        {currency}
        {product?.selected_unit_price?.is_promotion ? (
          formatCurrency(product?.selected_unit_price.promotion?.price)
        ) : (
          formatCurrency(product?.selected_unit_price.price)
        )}
      </span>
      }
      {product?.selected_unit_price?.is_promotion && (
        <span className="promotion-price">
          {currency}{formatCurrency(product?.selected_unit_price?.price)}
        </span>
      )}

    </>
  );
}
