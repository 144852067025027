import PropTypes from "prop-types";
import React, { useEffect, Suspense, lazy, useState } from "react";
import ScrollToTop from "./helpers/scroll-top";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import ReactGA from "react-ga4";
import { Spin, notification } from "antd";
import "./style.css"
import { ToastProvider } from "react-toast-notifications";
import { multilanguage, loadLanguages } from "redux-multilanguage";
import { connect, shallowEqual, useSelector } from "react-redux";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";
// Protected Urls
import OrderHistory from "./pages/other/OrderHistory";
import ShowInvoice from "./pages/other/ShowInvoice"
import ThankYou from "./pages/other/ThankYou";
import Checkout from "./pages/other/Checkout";
import Cart from "./pages/other/Cart";
import { getMe, setTokenViaImpersonate} from "./redux/actions/loginActions";
import QuickOrder from "./pages/other/QuickOrder";
import CustomerLedgerTable from "./components/Ledger";
import { getCustomerCart } from "./redux/actions/cartActions";
import TermsConditions from "./pages/other/Terms&Conditions";
import AgeConfirmationModal from "./components/modals/AgeConfirmationModal";
import WhatsAppIcon from "./assets/images/WhatsApp.png"
import {
  getCookie,
  setCookie,
  getParameterValue,
  sendPostRequest,
} from "./pages/constant/utils";
import WebFont from 'webfontloader'
import { UIProvider } from "./pages/auth/customerOnboarding/signUp-ui-context";
import { MainUIProvider } from "./UIContext";
// import AccountInfo from "./pages/other/Profile/my-account/my-account-tabs/AccountInfo";
// home pages
var GA_TRACKING_ID;
var GSC_HTML_TAG;
const HomePage = lazy(() => import("./pages/home"));
const CustomPages = lazy(() => import("./pages/customPages"));
// shop page
const ShopPage = lazy(() => import("./pages/shop"));
const Profile = lazy(() => import("./pages/other/Profile/Profile"));

// product pages
const Product = lazy(() => import("./pages/shop-product/Product"));
const ProductTabLeft = lazy(() =>
  import("./pages/shop-product/ProductTabLeft")
);
const ProductTabRight = lazy(() =>
  import("./pages/shop-product/ProductTabRight")
);
const ProductSticky = lazy(() => import("./pages/shop-product/ProductSticky"));
const ProductSlider = lazy(() => import("./pages/shop-product/ProductSlider"));
const ProductFixedImage = lazy(() =>
  import("./pages/shop-product/ProductFixedImage")
);

// other pages
const About = lazy(() => import("./pages/other/About"));
const Contact = lazy(() => import("./pages/other/Contact"));
const LoginRegister = lazy(() => import("./pages/auth/LoginRegister"));
const RegistgerCustomer = lazy(() =>
  import("./pages/auth/customerOnboarding/index")
);
const ForgotPassword = lazy(() => import("./pages/auth/NewForgotPassword"));
const NewPasswordOTP = lazy(() => import("./pages/NewPasswordOTP"));
const NewPassword = lazy(() => import("./pages/auth/NewPassword"));
const ChangePassword = lazy(() => import("./pages/auth/ChangePassword"));

const SaleAgentAssignCustomer = lazy(() =>
  import("./pages/other/SaleAgentAssignCustomer")
);
const Wishlist = lazy(() => import("./pages/other/Wishlist"));
const Compare = lazy(() => import("./pages/other/Compare"));

const NotFound = lazy(() => import("./pages/other/NotFound"));
const DeployStatus = lazy(() => import("./pages/deploy-status/DeployStatus"));

var link = document.querySelector("link[rel~='icon']");
if (!link) {
  link = document.createElement("link");
  link.rel = "icon";
  document.getElementsByTagName("head")[0].appendChild(link);
}

const App = ({ setting, userDetail, dispatch, cartItems }) => {

  const {whatsApp_toggle, tenant_whatsApp_country_code } = setting

  // Use a regular expression to replace spaces and dashes
  let cleanedPhoneNumber = tenant_whatsApp_country_code?.replace(/[\s-]/g, '');

  // Age confirmation modal
  const [ageConfirmationModal, setAgeConfirmationModal] = useState(true);

  // For Local Testing please use
  let {portal_url} = useSelector(
    (state) => state?.settingData?.['admins-pdanel']?.immutable_basic ?? {portal_url: undefined},
    shallowEqual
  );
  
  // Console For Dev/State/Prod testing
  console.log('portal_url', portal_url)
  portal_url = (portal_url?.startsWith('http:') ? portal_url.replace('http:', 'https:') : portal_url) || '';
  portal_url = portal_url?.endsWith('/') ? portal_url.slice(0, -1) : portal_url;

  // For Local Testing please use this definition of portal_url
  // const portal_url = 'http://localhost:3000' 

  document.title = setting?.ecom_title;
  GA_TRACKING_ID = setting?.ga_tracking_id;
  GSC_HTML_TAG = setting?.gsc_html_tag;

  useEffect(() => {
    if (GA_TRACKING_ID && GA_TRACKING_ID.length > 0) {
      ReactGA.initialize(GA_TRACKING_ID);
      ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    }
  }, [GA_TRACKING_ID])

  useEffect(() => {
    if (GSC_HTML_TAG) {
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = setting.gsc_html_tag;
      const metaTagElement = tempDiv.querySelector('meta');

      if (metaTagElement) {
        const metaName = metaTagElement.getAttribute('name');
        const metaContent = metaTagElement.getAttribute('content');
        if (metaName && metaContent && !document.querySelector(`meta[name="${metaName}"]`)) {
          const metaTag = document.createElement('meta');
          metaTag.name = metaName;
          metaTag.content = metaContent;
          document.head.appendChild(metaTag);
        }
      }
    }
  }, [GSC_HTML_TAG])

  useEffect(() => {
    if (localStorage.getItem("user")) {
      dispatch(getCustomerCart());
    }
    if (window.location.search.split("?customer_id=")[1]) {
          dispatch(
            setTokenViaImpersonate(window.location.search.split("&token=")[1])
          );
         
        }
  }, []);

  link.href = setting?.favicon;

  useEffect(() => {
    dispatch(
      loadLanguages({
        languages: {
          en: require("./translations/english.json"),
          fn: require("./translations/french.json"),
          de: require("./translations/germany.json"),
        },
      })
    );
  }, []);

  // handle age confirmation modal function
  function handleAgeConfirmation() {
    setCookie("ageConfirmation", "true", 365); // Set the cookie to true for 1 year
    setAgeConfirmationModal(false);
  }

  // check if age confirmation is already done or not in Cookie storage
  useEffect(() => {
    const ageConfirmationCookie = getCookie("ageConfirmation");

    if (ageConfirmationCookie !== "true" || ageConfirmationCookie === null) {
      setAgeConfirmationModal(true); // Show the modal only if the cookie is not set to 'true' i did this because on true value the modal was showing for a second and then disappearing
    }
    if (ageConfirmationCookie === "true" || ageConfirmationCookie !== null) {
      setAgeConfirmationModal(false); // Show the modal only if the cookie is not set to 'true' i did this because on true value the modal was showing for a second and then disappearing
    }
  }, []);

  // Getting Query params from url
  const token = getParameterValue("token");
  const customer_id = getParameterValue("customer_id");

  useEffect(() => {
    dispatch(getMe())
    if (token && customer_id) {
      sendPostRequest(customer_id, token);
      // get latest user data
    }
  }, [token, customer_id]);

  const getFontName = (fontFamily) => {
    return fontFamily.match(/"(.*?)"/)?.[1] || '';
  }

  function applyStyleSettings(styles) {
    if (!styles) return;

    let themeCSS = '';
    let styleValue = '';

    let themeStyleTag = document.getElementById('theme-styles');

    if (!themeStyleTag) {
      themeStyleTag = document.createElement('style');
      themeStyleTag.type = 'text/css';
      themeStyleTag.id = 'theme-styles';
      document.head.appendChild(themeStyleTag);
    }
  
    Object.keys(styles).forEach((key) => {
      styleValue = styles[key];
      themeCSS += `--style-${key.replaceAll('_', '-')}:${styleValue};`;

      if (key.endsWith('_family')) {
        const fontName = getFontName(styleValue);

        const isFontLoaded = Array.from(document.querySelectorAll('link')).some((link) =>
          link.href.includes(`family=${fontName}`)
        );

        if (!isFontLoaded) {
          WebFont.load({
            classes: false,
            google: {
              families: [fontName],
            },
          });
        }
      }
    });
  
    themeStyleTag.innerText = `:root{${themeCSS}}`;
  }
  
  const styleSettings = [setting.ecom_styles_settings]
  styleSettings.forEach((styles) => applyStyleSettings(styles))

  const [notificationActive, setNotificationActive] = useState(false);

  const showEcomFailureNotification = (message, description) => {
    if (notificationActive) return;

    setNotificationActive(true);
    notification.error({
      message,
      description,
      duration: 10,
      onClose: () => setNotificationActive(false),
    });
  };

  useEffect(() => {
    const handleMessage = (event) => {
      if (!notificationActive && !portal_url && portal_url !== '') {
        showEcomFailureNotification('Please try again later', 'Theme updates are not available right now');
        return;
      }

      if (portal_url !== event.origin) return;

      const { type, styleData } = event.data || {};

      if (type === "UPDATE_CSS") {
        console.log('UPDATE_CSS', styleData);
        applyStyleSettings(styleData);
      }
    };

    window.addEventListener("message", handleMessage);
    
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [notificationActive]);

  return (
    <>
    <MainUIProvider>
      <ToastProvider placement="top-right">
        <BreadcrumbsProvider>
          <Router>
            <ScrollToTop>
              <Spin spinning={userDetail?.saleAgentViaCustomerLoader}>
                <Suspense
                  fallback={
                    <div className="flone-preloader-wrapper">
                      <div className="flone-preloader">
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  }
                >
                  {ageConfirmationModal && (
                    <AgeConfirmationModal
                      ageConfirmationCheck={ageConfirmationModal}
                      onOk={handleAgeConfirmation}
                    />
                  )}
                  <Switch>
                    <Route
                      exact
                      path={process.env.PUBLIC_URL + "/"}
                      component={HomePage}
                    />
                    {/* make index page */}
                    <Route
                      path={process.env.PUBLIC_URL + "/home"}
                      component={HomePage}
                    />
                    {/* Shop pages */}
                    <Route
                      path={process.env.PUBLIC_URL + "/shop"}
                      component={ShopPage}
                    />
                    {/* Shop product pages */}
                    <Route
                      path={process.env.PUBLIC_URL + "/product/:slug"}
                      render={(routeProps) => (
                        <Product
                          {...routeProps}
                          key={routeProps?.match.params.slug}
                        />
                      )}
                    />
                    <Route
                      path={process.env.PUBLIC_URL + "/product/:slug"}
                      component={ProductTabLeft}
                    />
                    <Route
                      path={process.env.PUBLIC_URL + "/product/:slug"}
                      component={ProductTabRight}
                    />
                    <Route
                      path={process.env.PUBLIC_URL + "/product-sticky/:id"}
                      component={ProductSticky}
                    />
                    <Route
                      path={process.env.PUBLIC_URL + "/product-slider/:id"}
                      component={ProductSlider}
                    />
                    <Route
                      path={process.env.PUBLIC_URL + "/product-fixed-image/:id"}
                      component={ProductFixedImage}
                    />
                    <Route
                      path={process.env.PUBLIC_URL + "/terms-conditions"}
                      component={TermsConditions}
                    />
                    {/* Other pages */}
                    <Route
                      path={process.env.PUBLIC_URL + "/profile/my-account"}
                      component={() => {
                        return !userDetail?.user ? (
                          <Redirect to="/login" />
                        ) : (
                          <Profile route="/profile/my-account" />
                        )
                      }
                      }
                    />

                    <Route
                      path={process.env.PUBLIC_URL + "/profile/order-history"}
                      component={() => <Profile route="/profile/order-history" />}
                    />
                    <Route
                      path={process.env.PUBLIC_URL + "/profile/wishlist"}
                      component={() => <Profile route="/profile/wishlist" />}
                    />

                    <Route
                      path={process.env.PUBLIC_URL + "/profile/statements"}
                      component={() => <Profile route="/profile/statements" />}
                    />
                    <Route path={process.env.PUBLIC_URL + "/profile"}
                      component={() => {
                        return !userDetail?.user ? (
                          <Redirect to="/login" />
                        ) : (
                          <Profile route="/profile/my-account" />
                        )
                      }
                      } />

                    <Route
                      path={process.env.PUBLIC_URL + "/about"}
                      component={About}
                    />
                    {/* Contact */}
                    <Route
                      path={process.env.PUBLIC_URL + "/contact"}
                      component={Contact}
                    />

                    {/* <Route path={process.env.PUBLIC_URL + "/profile/my-account"}>
                      {!userDetail?.user ? (
                        <Redirect to="/login" />
                      ) : (
                        <AccountInfo />
                      )}
                    </Route> */}
                    <Route
                      path={process.env.PUBLIC_URL + "/login"}
                      component={LoginRegister}
                    />
                    <Route
                      path={process.env.PUBLIC_URL + "/signup"}
                      // component={RegistgerCustomer}
                      render={(props) => (
                        <UIProvider>
                          <RegistgerCustomer {...props} />
                        </UIProvider>
                      )}
                    />
                    <Route
                      path={
                        process.env.PUBLIC_URL + "/sale-agent/assign-customers"
                      }
                      component={SaleAgentAssignCustomer}
                    />
                    <Route path={process.env.PUBLIC_URL + "/order-history"}>
                      {userDetail.user === undefined ? (
                        <Redirect to="/login" />
                      ) : (
                        <OrderHistory />
                      )}
                    </Route>

                    <Route path={process.env.PUBLIC_URL + "/change-password"}>
                      {userDetail.user === undefined ? (
                        <Redirect to="/login" />
                      ) : (
                        <ChangePassword />
                      )}
                    </Route>
                    <Route path={process.env.PUBLIC_URL + "/ledger"}>
                      {userDetail.user === undefined ? (
                        <Redirect to="/login" />
                      ) : (
                        <CustomerLedgerTable />
                      )}
                    </Route>
                    <Route path={process.env.PUBLIC_URL + "/cart"}>
                      {userDetail.user === undefined ? (
                        <Redirect to="/login" />
                      ) : (
                        <Cart />
                      )}
                    </Route>
                    <Route
                      path={process.env.PUBLIC_URL + "/wishlist"}
                      component={Wishlist}
                    />
                    <Route path={process.env.PUBLIC_URL + "/quick-order"}>
                      {userDetail.user === undefined ? (
                        <Redirect to="/login" />
                      ) : (
                        <QuickOrder location={{ pathname: "/quick-order" }} />
                      )}
                    </Route>
                    <Route
                      path={process.env.PUBLIC_URL + "/compare"}
                      component={Compare}
                    />
                    {/* Checkout url */}
                    <Route path={process.env.PUBLIC_URL + "/checkout"}>
                      {userDetail.user === undefined ? (
                        <Redirect to="/login" />
                      ) : cartItems?.length > 0 ? (
                        <Checkout />
                      ) : (
                        <Redirect to="/shop" />
                      )}
                    </Route>
                    <Route path={process.env.PUBLIC_URL + "/invoice/:id"}>
                      {userDetail.user === undefined ? (
                        <Redirect to="/login" />
                      ) : (
                        <ShowInvoice />
                        // <h1>tets</h1>
                      )}
                    </Route>
                    <Route path={process.env.PUBLIC_URL + "/thank-you"}>
                      {userDetail.user === undefined ? (
                        <Redirect to="/login" />
                      ) : (
                        <ThankYou />
                      )}
                    </Route>
                    <Route
                      path={process.env.PUBLIC_URL + "/auth/forgot-password"}
                      component={ForgotPassword}
                    />
                    <Route
                      path={process.env.PUBLIC_URL + "/auth/new-password-otp"}
                      component={NewPasswordOTP}
                    />
                    <Route
                      page={process.env.PUBLIC_URL + "/:slug"}
                      component={CustomPages}
                      exact
                    />
                    <Route
                      path={process.env.PUBLIC_URL + "/auth/new-password"}
                      component={NewPassword}
                    />
                    <Route
                      path={process.env.PUBLIC_URL + "/status"}
                      component={DeployStatus}
                    />
                    <Route exact path='/not-found' component={NotFound} />
                    <Redirect to="/not-found" />
                  </Switch>
                </Suspense>
              </Spin>
            </ScrollToTop>
           
          </Router>
        </BreadcrumbsProvider>
      </ToastProvider>
    </MainUIProvider>
    {whatsApp_toggle && <div className="fixed-bottom-right">
     <a href={`https://wa.me/${cleanedPhoneNumber}`} target="_blank">
     <img src={WhatsAppIcon} alt="Caht on WhatsApp" />
     </a>
   </div>}
   </>
  );
};

App.propTypes = {
  cartItems: PropTypes.array,
  dispatch: PropTypes.func,
  userDetail: PropTypes.object,
  setting: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    userDetail: state.userData,
    setting: state.settingData.ecom.basic,
    cartItems: state.cartData,
  };
};

export default connect(mapStateToProps)(multilanguage(App));
