import React from "react";
import { Modal } from "antd";
import AchPaymentForm from "./ACH/achPaymentForm";

const AchModal = ({ show, onHide, cb, setBankInfo, isDisabled }) => {
  return (
    <>
      <Modal
        open={show}
        title={<div className="modal-label">ACH Payment</div>}
        centered
        width={1134}
        closable={false}
        footer={null}
      >
        <AchPaymentForm setBankInfo={setBankInfo} onHide={onHide} cb={cb} isDisabled={isDisabled} />
      </Modal>
    </>
  );
};

export default AchModal;
