import axios from "axios";
import { PRODUCT_URL, BRAND_URL } from "../../constants/config";

// Product Contant Block
import {
  FETCH_PRODUCTS_SUCCESS,
  FETCH_SINGLE_PRODUCT,
  FETCH_FEATURED_PRODUCT,
  EMPTY_DETAIL_PRODUCT,
  UPDATE_SEARCH_KEY,
  FETCH_SUGGESTED_PRODUCT,
  FETCH_BRANDS,
  SET_LOADING_STATE,
  SET_BRAND_LOADING_STATE,
  FETCH_HOTSELLER_PRODUCT,
  FETCH_PRODUCT_LOADING_SUCCESS,
  FETCH_PRODUCTS_FAILURE,
  FETCH_PRODUCTS_START,
  FETCH_NEWARRIVALS_PRODUCT,
} from "../constant_types";

export const fetchProductsStart = () => ({
  type: FETCH_PRODUCTS_START,
});

const fetchProductsSuccess = (products, Page) => ({
  type: FETCH_PRODUCTS_SUCCESS,
  payload: { products, Page },
});
export const fetchProductsFailure = () => ({
  type: FETCH_PRODUCTS_FAILURE,
});
const fetchFeaturedProduct = (product) => ({
  type: FETCH_FEATURED_PRODUCT,
  payload: product,
});

const fetchHotSellerProduct = (product) => ({
  type: FETCH_HOTSELLER_PRODUCT,
  payload: product,
});
const fetchNewArrival = (product) => ({
  type: FETCH_NEWARRIVALS_PRODUCT,
  payload: product,
});

const fetchBrand = (brand) => ({
  type: FETCH_BRANDS,
  payload: brand,
});
const fetchSuggestedProduct = (product) => ({
  type: FETCH_SUGGESTED_PRODUCT,
  payload: product,
});

const fetchSingleProduct = (product) => ({
  type: FETCH_SINGLE_PRODUCT,
  payload: product,
});
const setEmptyDetailProduct = (detailProduct) => ({
  type: EMPTY_DETAIL_PRODUCT,
  payload: detailProduct,
});
const setProductSearch = (search_key) => ({
  type: UPDATE_SEARCH_KEY,
  payload: search_key,
});

const setLoadingStateToStore = (state) => ({
  type: SET_LOADING_STATE,
  payload: state,
});
const setLoadingStateToStoreBrand = (state) => ({
  type: SET_BRAND_LOADING_STATE,
  payload: state,
});
// fetch products
export const fetchProducts = (params, Page = 1, setTotalCount) => {
  return (dispatch) => {
    dispatch(fetchProductsStart());
    axios
      .get(`${PRODUCT_URL}/list2`, {
        params: { ...params, sort_by: params?.sort_by || "created_at:desc" },
      })
      .then((res) => {
        if (setTotalCount) {
          setTotalCount(res.data.totalCount);
        }
        dispatch(fetchProductsSuccess(res.data, Page));
      })
      .catch((err) => {
        dispatch(fetchProductsFailure());
      });
  };
};

export const fetchProductRequest = (params) => {
  return axios.get(`${PRODUCT_URL}/list2`, { params });
};

export const emptyDetailProduct = (detailProduct) => {
  return (dispatch) => {
    dispatch(setEmptyDetailProduct(detailProduct));
  };
};
export const searchProduct = (search_key) => {
  return (dispatch) => {
    dispatch(setProductSearch(search_key));
  };
};

export const fetchFeaturedProducts = (params) => {
  return (dispatch) => {
    dispatch(setLoadingStateToStore(true))
    axios
      .get(`${PRODUCT_URL}/list2`, {
        params: { ...params, sort_by: "created_at:desc" },
      })
      .then((res) => {
        dispatch(fetchFeaturedProduct(res.data));
      })
      .catch((err) => {dispatch(setLoadingStateToStore(false))});
  };
};
export const fetchHotSellersProducts = (params) => {
  return (dispatch) => {
    axios
      .get(`${PRODUCT_URL}/list2`, {
        params: { ...params, sort_by: "created_at:desc" },
      })
      .then((res) => {
        dispatch(fetchHotSellerProduct(res.data));
      })
      .catch((err) => {});
  };
};
export const fetchNewArrivals = (params) => {
  return (dispatch) => {
    axios
      .get(`${PRODUCT_URL}/list2`, {
        params: { ...params, sort_by: "created_at:desc" },
      })
      .then((res) => {
        dispatch(fetchNewArrival(res.data));
      })
      .catch((err) => {console.log(err);});
  };
};

export const fetchBrands = (params) => {
  return (dispatch) => {
    dispatch(setLoadingStateToStoreBrand(true))
    axios
      .get(`${BRAND_URL}/list`, { params })
      .then((res) => {
        dispatch(fetchBrand(res.data));
      })
      .catch((err) => {dispatch(setLoadingStateToStoreBrand(false))});
  };
};
export const fetchProductByBrand = (params) => {
  return axios.get(`${PRODUCT_URL}/list2`, {
    params: { ...params, sort_by: "created_at:desc" },
  });
};
export const fetchSuggestedProducts = (params) => {
  return (dispatch) => {
    axios
      .get(`${PRODUCT_URL}/list2`, {
        params: { ...params, sort_by: "created_at:desc" },
      })
      .then((res) => {
        dispatch(fetchSuggestedProduct(res.data));
      })
      .catch((err) => {});
  };
};

export const fetchProductBySlug = (product, detailShop, shopListing) => {
  return (dispatch) => {
    axios
      .get(`${PRODUCT_URL}/slug/${product.slug}`)
      .then((res) => {
        const { entity } = res.data;
        dispatch(
          fetchSingleProduct({
            ...entity,
            selected_unit_price: product.selected_unit_price,
            saleable_qty: product.saleable_qty,
          })
        );
        detailShop();
      })
      .catch((err) => {
        shopListing();
      });
  };
};

export const fetchProductBySlugOnPageReload = (slug, fn) => {
  return (dispatch) => {
    axios
      .get(`${PRODUCT_URL}/slug/${slug}`)
      .then((res) => {
        const { entity } = res.data;
        dispatch(
          fetchSingleProduct({
            ...entity,
          })
        );
        setTimeout(() => {
          dispatch(setLoadingState(false));
        }, 1000);
      })
      .catch((err) => {
        fn();
      });
  };
};

export function fetchProductsForQuick(params, Page) {
  return axios.get(`${PRODUCT_URL}/list2`, {
    params: { ...params, sort_by: "name:desc" },
  });
}

export function setLoadingState(loadingState) {
  return (dispatch) => {
    dispatch(setLoadingStateToStore(loadingState));
  };
}
