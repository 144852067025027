import axios from "axios";
import { TAG_URL } from "../../constants/config";
export const FETCH_TAG_LIST = "FETCH_TAG_LIST";
const fetchTagSuccess = (tags) => ({
  type: FETCH_TAG_LIST,
  payload: tags,
});

// fetch products
export const fetchTags = (params) => {
  return (dispatch) => {
    axios
      .get(`${TAG_URL}/list`, { params })
      .then((res) => {
        dispatch(fetchTagSuccess(res.data));
      })
      .catch((err) => {});
  };
};
