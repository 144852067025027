import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Skeleton, Col, Row } from "antd";

const FooterSkeleton = () => {
    return (
        <Fragment>
            {[1].map((item, index) => (
                <Row
                    className="gutter-row"
                    xl={6}
                    lg={6}
                    md={12}
                    sm={12}
                    xs={12}
                    key={index}
                >
                    <div style={{ display: "flex" }}>
                        <div className="footer" style={{ backgroundColor: "white", padding: '10px' }}>
                            <div className="row-one">
                                <div className="column-one">
                                    <div className="first-half">
                                        <Link style={{ display: 'block', marginBottom: '10px' }}>
                                            <Skeleton.Button
                                                active
                                                style={{ width: '50px' }} // Make Skeleton full width
                                            />
                                        </Link>
                                        <Link style={{ display: 'block', marginBottom: '10px' }}>
                                            <Skeleton
                                                active
                                                style={{ width: '100%' }} // Make Skeleton full width
                                            />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="footer" style={{ backgroundColor: "white", padding: '10px' }}>
                            <div className="row-one">
                                <div className="column-one">
                                    <div className="first-half">
                                        <Link style={{ display: 'block', marginBottom: '10px' }}>
                                            <Skeleton
                                                active
                                                style={{ width: '100%' }} // Make Skeleton full width
                                            />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="footer" style={{ backgroundColor: "white", padding: '10px' }}>
                            <div className="row-one">
                                <div className="column-one">
                                    <div className="first-half">
                                        <Link style={{ display: 'block', marginBottom: '10px' }}>
                                            <Skeleton
                                                active
                                                style={{ width: '100%' }} // Make Skeleton full width
                                            />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="footer" style={{ backgroundColor: "white", padding: '10px' }}>
                            <div className="row-one">
                                <div className="column-one">
                                    <div className="first-half">
                                        <Link style={{ display: 'block', marginBottom: '10px' }}>
                                            <Skeleton
                                                active
                                                style={{ width: '100%' }} // Make Skeleton full width
                                            />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Row>
            ))}
        </Fragment>
    );
};

export default FooterSkeleton;
