// Packages block
import uuid from "uuid/v4";

// Constant Types Block
import {
  ADD_TO_CART,
  DECREASE_QUANTITY,
  INCREASE_QUANTITY,
  DELETE_FROM_CART,
  DELETE_ALL_FROM_CART,
  BULK_ADD_TO_CART,
  SET_CART_FROM_API,
} from "../constant_types";

const initState = [];

const cartReducer = (state = initState, action) => {
  const cartItems = state,
    product = action.payload;

  if (action.type === ADD_TO_CART) {
    // for non variant products
    if (product.variation === undefined) {
      const cartItem = cartItems.filter((item) => item.id === product.id)[0];
      if (cartItem === undefined) {
        return [
          ...cartItems,
          {
            ...product,
            quantity: product.quantity ? product.quantity : 1,
            cartItemId: uuid(),
          },
        ];
      } else {
        return cartItems.map((item) =>
          item.cartItemId === cartItem.cartItemId
            ? {
                ...item,
                quantity: product.quantity
                  ? item.quantity + product.quantity
                  : item.quantity,
              }
            : item
        );
      }
      // for variant products
    } else {
      const cartItem = cartItems.filter(
        (item) =>
          item.id === product.id &&
          product.selectedProductColor &&
          product.selectedProductColor === item.selectedProductColor &&
          product.selectedProductSize &&
          product.selectedProductSize === item.selectedProductSize &&
          (product.cartItemId ? product.cartItemId === item.cartItemId : true)
      )[0];

      if (cartItem === undefined) {
        return [
          ...cartItems,
          {
            ...product,
            quantity: product.quantity ? product.quantity : 1,
            cartItemId: uuid(),
          },
        ];
      } else if (
        cartItem !== undefined &&
        (cartItem.selectedProductColor !== product.selectedProductColor ||
          cartItem.selectedProductSize !== product.selectedProductSize)
      ) {
        return [
          ...cartItems,
          {
            ...product,
            quantity: product.quantity ? product.quantity : 1,
            cartItemId: uuid(),
          },
        ];
      } else {
        return cartItems.map((item) =>
          item.cartItemId === cartItem.cartItemId
            ? {
                ...item,
                quantity: product.quantity
                  ? item.quantity + product.quantity
                  : item.quantity + 1,
                selectedProductColor: product.selectedProductColor,
                selectedProductSize: product.selectedProductSize,
              }
            : item
        );
      }
    }
  }

  if (action.type === BULK_ADD_TO_CART) {
    const preCartItems = state;
    const { items, allItems } = action.payload;
    if (allItems == "allItems") {
      return items;
    } else {
      if (items && items?.length > 0) {
        const cartItems = items.map((item) => {
          const cartItem = preCartItems.filter(
            (product) => item.id === product.id
          )[0];
          if (cartItem === undefined) {
            return {
              ...item,
              quantity: item.enterQuantity ? item.enterQuantity : 1,
              cartItemId: uuid(),
              selectedProductColor: null,
              selectedProductSize: null,
            };
          } else if (item.id === cartItem.id) {
            return {
              ...item,
              quantity: cartItem?.quantity ? item.enterQuantity : 1,
            };
          }
        });
        return [...cartItems];
      }
    }
  }

  if (action.type === DECREASE_QUANTITY) {
    // if (product.quantity <= 1) {
    //   const remainingItems = (cartItems, product) =>
    //     cartItems.filter(
    //       (cartItem) => cartItem.id !== product.id
    //     );
    //   return remainingItems(cartItems, product);
    // } else {
    if (product.quantity > 0) {
      return cartItems
        .map((item) =>
          item.id === product.id
            ? { ...item, quantity: item.quantity - 1 }
            : item
        )
        ?.filter((item) => item.quantity > 0);
    }
    // }
  }

  if (action.type === INCREASE_QUANTITY) {
    return cartItems.map((item) =>
      item.id === product.id ? { ...item, quantity: item.quantity + 1 } : item
    );
  }

  if (action.type === DELETE_FROM_CART) {
    const remainingItems = (cartItems, product) => {
      return cartItems.filter((cartItem) => cartItem.id !== product.id);
    };
    return remainingItems(cartItems, action.payload);
  }

  if (action.type === DELETE_ALL_FROM_CART) {
    return cartItems.filter((item) => {
      return false;
    });
  }

  if (action.type === SET_CART_FROM_API) {
    return action.payload.map((cartItem) => {
      return { ...cartItem.product, quantity: cartItem.qty };
    });
  }

  return state;
};

export default cartReducer;
