import * as Yup from 'yup';

const ADDRESS_SCHEMA = Yup.object().shape({
    line_1: Yup.string().required('Line 1 is required'),
    line_2: Yup.string().required('Line 2 is required'),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    country: Yup.string(),
    zip: Yup.string()
    .matches(/^\d{5}$/, 'Business zip must consist of 5 digit')
    .required('ZIP code is required'),
  });
  export const ACCOUNT_SCHEMA = Yup.object().shape({
    account_number: Yup.string()
    .matches(/^.{0,17}$/, 'Account number should have a maximum of 17 characters.')
    .required("Account Number is a required "),
    routing_number: Yup.string()
    .matches(/^\d{9}$/, 'Bank routing number must consist of 9 digit')
    .required("Routing Number is a required "),
    account_name: Yup.string().required("Account name is a required "),
    account_type: Yup.string().required("Account Type is a required "),
  });


export const BANK_SCHEMA = Yup.object().shape({
  custom: ACCOUNT_SCHEMA.required(),
});

const BUSINESS_SCHEMA = Yup.object().shape({
    name: Yup.string().required('Business name is required'),
    tin: Yup.string().transform((value, originalValue) => {
      if (originalValue) {
        return originalValue.replace(/_/g, "");
      }
      return value;
    })
    .matches(/^\d{9}$/, 'Business tin number must consist of 9 digit')
    .required('Business tin number is required'),
  });
export const ON_BOARDING_CUSTOMER_SCHEMA = Yup.object().shape({
    should_do_kyb: Yup.boolean().oneOf([true], 'KYB should be true'),
    phone: Yup.string()
    .matches(/^\+1\d{10}$/, 'Phone number must start with "+1" and have 10 digits')
    .required('Phone number is required'),
    // business: BUSINESS_SCHEMA.required(),
    bank_account: BANK_SCHEMA.required(),
    // address: ADDRESS_SCHEMA.required(),
  });
  export const EDIT_BANK_SCHEMA=Yup.object().shape({
    bank_account: BANK_SCHEMA.required(),
  });

  export const AccountType=[
    "savings",
    "checking",
    // "Money Market Account",
    // "Certificate of Deposit (CD)",
    // "Individual Retirement Account (IRA)",
    // "Health Savings Account (HSA)",
    // "Business Accounts"
  ]

  export function maskString(inputString="",replaceWith=undefined) {
    const visibleChars = 3; // Number of characters to keep visible at the end
    const maskedLength = Math.max(inputString.length - visibleChars, 0);
    const maskedPart = (replaceWith||'*').repeat(maskedLength) + inputString.slice(-visibleChars);
    return maskedPart;
  }

  export function convertStatementToObject(existingBusiness,statement) {
    const [property, value] = statement.split(":").map(item => item.trim());
    const keys = property.split(".");
    const nestedObject = createNestedObject(keys, value === "true" ? true : false);
    const updatedBusiness = mergeObjects(existingBusiness, nestedObject);
    return updatedBusiness;
  }

  function mergeObjects(obj1, obj2) {
    return {
      ...obj1,
      ...obj2
    };
  }
  
  function createNestedObject(keys, value) {
    if (keys.length === 1) {
      return { [keys[0]]: value };
    }
    
    const [key, ...remainingKeys] = keys;
    return { [key]: createNestedObject(remainingKeys, value) };
  }

  export const initialValues = {
    should_do_kyb: true,
    phone: '',
    business: {
      name: '',
      tin: '',
    },
    bank_account: {
      custom: {
        account_number: '',
        routing_number: '',
        account_name: '',
        account_type: '',
      },
    },
    address: {
      line_1: '',
      line_2: '',
      city: '',
      state: '',
      country: 'United States',
      zip: '',
    }
  };
  export const initialValuesBank = {
    bank:{
      custom: {
        account_name:'',
        account_number: '',
        routing_number: '',
        account_type: '',
    },}
  };
  