import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import MetaTags from "react-meta-tags";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { connect, useDispatch } from "react-redux";
import { Table } from "antd";
import { Dropdown } from "antd";

import {
  addToCart,
  decreaseQuantity,
  deleteFromCart,
  deleteAllFromCart,
} from "../../redux/actions/cartActions";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import { fetchAllOrders } from "../../redux/actions/checkoutActions";
import OrderDetailModal from "../../components/checkout/OrderDetailModal";
import { formatCurrency, formatDateTime } from "./../constant/utils";
import { Spin } from "antd";
import { store } from "../..";
import { copyOrder } from "./../../redux/actions/checkoutActions";

const OrderHistory = ({ currency, userDetail, orderDetail }) => {
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [id, setId] = useState(null);

  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    store.dispatch(fetchAllOrders(params, userDetail, setLoading));
  }, []);

  const handleDetail = (id) => {
    setId(id);
    setModalShow(true);
  };
  const handleModalClose = () => {
    setModalShow(false);
  };

  return (
    <Fragment>
      <MetaTags>
        <title>{currency?.ecom_title} | Order History</title>
        <meta
          name="description"
          content={`Order History page of ${currency?.ecom_title} eCommerce template.`}
        />
      </MetaTags>

      <BreadcrumbsItem to={process.env.PUBLIC_URL + "/"}>Home</BreadcrumbsItem>
      <BreadcrumbsItem to={process.env.PUBLIC_URL + history.location.pathname}>
        Order History
      </BreadcrumbsItem>

      <LayoutOne headerTop="visible">
        {/* breadcrumb */}
        <Breadcrumb />
        <div className="cart-main-area pt-20 pb-100">
          <div className="container-fluid">
            {orderDetail && orderDetail.length >= 1 ? (
              <Fragment>
                <div className="row">
                  <div className="col-12">
                    <div style={{ height: "900px", overflowY: "scroll" }}>
                      <Spin spinning={loading}>
                        <Table striped style={{ height: "140px" }}>
                          <thead>
                            <tr>
                              <th>ID</th>
                              <th>Order No</th>
                              <th>Customer Name</th>
                              <th>Business Name</th>
                              <th>Order Date</th>
                              <th>Delivery Date</th>
                              <th>Total Price</th>
                              <th>Action(s)</th>
                            </tr>
                          </thead>
                          <tbody>
                            {orderDetail.map((order, key) => (
                              <tr tabIndex={key}>
                                <td> {Number(orderDetail?.length - key)}</td>
                                <td>{order.no}</td>
                                <td>{order.customer_name}</td>
                                <td>{order.business_name}</td>
                                <td>{formatDateTime(order.order_date)}</td>
                                <td>
                                  {formatDateTime(order.pickup_delivery_date)}
                                </td>
                                <td>
                                  {currency.currency_symbol}
                                  {formatCurrency(
                                    order.total_price
                                  ).toLocaleString()}
                                </td>
                                <td>
                                  <Dropdown>
                                    <Dropdown.Toggle id="dropdown-basic">
                                      <i
                                        className="pe-7s-more"
                                        size="large"
                                      ></i>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      <Dropdown.Item
                                        onClick={() => {
                                          handleDetail(order.id);
                                        }}
                                      >
                                        View Details
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        onClick={() => {
                                          history.push(`/invoice/${order.id}`);
                                        }}
                                      >
                                        View Invoice
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        onClick={() => {
                                          dispatch(
                                            copyOrder(
                                              order.id,
                                              {
                                                copy: 1,
                                                channel_id: currency.channel_id,
                                              },
                                              setLoading,
                                              history
                                            )
                                          );
                                        }}
                                      >
                                        Copy Order
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Spin>
                    </div>
                    <OrderDetailModal
                      id={id}
                      show={modalShow}
                      handleModalClose={handleModalClose}
                    />
                  </div>
                </div>
              </Fragment>
            ) : (
              <div className="row">
                <div className="col-lg-12">
                  <div className="item-empty-area text-center">
                    <div className="item-empty-area__icon mb-30">
                      <i className="pe-7s-cart"></i>
                    </div>
                    <div className="item-empty-area__text">
                      No Order Available <br />{" "}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </LayoutOne>
    </Fragment>
  );
};

OrderHistory.propTypes = {
  addToCart: PropTypes.func,
  cartItems: PropTypes.array,
  currency: PropTypes.object,
  orderDetail: PropTypes.object,
  singleOrder: PropTypes.object,
  userDetail: PropTypes.object,
  decreaseQuantity: PropTypes.func,
  deleteAllFromCart: PropTypes.func,
  deleteFromCart: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    cartItems: state.cartData,
    currency:state.settingData?.["admin-panel"]?.basic||{},
    userDetail: state.userData,
    orderDetail: state.saleData.orderEntities,
    singleOrder: state.saleData.singleOrder,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (item, addToast, quantityCount) => {
      dispatch(addToCart(item, addToast, quantityCount));
    },
    decreaseQuantity: (item, addToast) => {
      dispatch(decreaseQuantity(item, addToast));
    },
    deleteFromCart: (item, addToast) => {
      dispatch(deleteFromCart(item, addToast));
    },
    deleteAllFromCart: (addToast) => {
      dispatch(deleteAllFromCart(addToast));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderHistory);
