import {
    FETCH_BRANDS, SET_BRAND_LOADING_STATE,
} from "../constant_types";

const initState = {
    entities: [],
    featuredBrands: [],
    totalCount: null,
    pageSize: null,
    loading:false,

};

const brandReducer = (state = initState, action) => {

    if (action.type === FETCH_BRANDS) {
        return {
            ...state,
            entities: action.payload.entities,
            featuredBrands: action.payload.entities.filter((brand) => brand.is_featured === true),
            pageSize: action.payload.pageSize,
            totalCount: action.payload.totalCount,
            loading:false
        };
    }
    if (action.type === SET_BRAND_LOADING_STATE) {
        return {
            ...state,
            loading:action.payload
        }
    }
    return state;
};

export default brandReducer;
