import { Form, Spin, notification } from "antd";
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { ErrorMessage, Field, Formik } from "formik";
import PropTypes from "prop-types";

import Button from "../../components/utils/Button";
import {
  saleOrderPayload,
  taxType,
  paymentType,
  SHIPPING_TYPE,
  Units,
  TERMS_AND_CONDITION,
  PRIVACY_POLICY,
  convertToSlug,
  DEFAULT_DELIVERY_CHARGES
} from "../constant/Constants";
import "moment-timezone";
import { useToasts } from "react-toast-notifications";
import {
  backendFormatDateTime,
  customPagesPrivacyPolicyValidation,
  customPagesTermsValidation,
  formatCurrency,
  formatTaxAmount,
  getPriceRelatedToCustomer,
  validationMessageForTermsAndConditionAndPrivacyPolicy,
} from "../constant/utils";
import { Link, useHistory } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { useMainUIContext } from "../../UIContext";


const CartFinal = ({
  buttonStyle,
  salePayload,
  placeOrder,
  cartItems,
  isSelected,
  actionsLoading,
  handleNMISetup,
  disabled,
  setIsSelected,
  activeTab,
  bankInfo,
  userDetail,
  saleTotal,
  calculation,
  additionalNotes,
  payment,
  savedAmount,
  customPages,
  settings,
  adminSettings
}) => {
  console.log("🚀 ~ calculation:", calculation)
  const mainUIContext = useMainUIContext();
  const mainUIProps = useMemo(() => {
    return {
      setCheckoutShippingDetails: mainUIContext.setCheckoutShippingDetails,
    };
  }, [mainUIContext]);
  const { setCheckoutShippingDetails } = mainUIProps;
  const { tax, tax_type, delivery_charges, payments } = adminSettings.pos || {}
  const { currency_symbol, timezone } = adminSettings.basic
  const {
    price_display_for_secure_user,
    price_display_for_unsecure_user,
    // payments,
  } = settings || {};
  const { addToast } = useToasts();
  let _acceptedTerms = false;

  return (
    <Formik
      initialValues={{
        ...saleOrderPayload,
        ...salePayload,
        dispatch_date: backendFormatDateTime(Date.now()),
      }}
      onSubmit={(values) => {
        placeOrder({ ...values, ...salePayload, note: additionalNotes });
      }}
    >
      {({ values, setFieldValue, handleSubmit }) => (
        <div className="cart-wrapper">
          <div className="">
            <div className="">
              <h3>Order Summary</h3>

              <div className="checkout-cart-info">
                {((userDetail && price_display_for_secure_user) ||
                  (!userDetail &&
                    price_display_for_unsecure_user)) && (
                    <>
                      <div className="checkout-cart-items">
                        <span>Subtotal</span>
                        <p>
                          {`${currency_symbol}${formatCurrency(
                            cartItems.reduce(
                              (total, item) =>
                                getPriceRelatedToCustomer(
                                  userDetail,
                                  item
                                ) *
                                item.quantity +
                                total,
                              0
                            )
                          )}`}
                        </p>
                      </div>
                      {calculation?.saved_amount ? <div className="checkout-cart-items">
                        <span>You Saved</span>
                        <p>{`${currency_symbol}${formatCurrency(calculation.saved_amount) || 0
                          }`}</p>
                      </div> : null}
                      {+salePayload.shipping_type ==
                        SHIPPING_TYPE.DELIVERY.value ? (
                        <div className="checkout-cart-items">
                          <span>Delivery Charges</span>
                          <p>{`${currency_symbol}${Number(delivery_charges ?? DEFAULT_DELIVERY_CHARGES)}`}</p>
                        </div>
                      ) : null}
                      {/* <div className="checkout-cart-items">
                        <span>Estimated Tax</span>
                        <p>
                          {" "}
                          {tax_type == 2
                            ? currency_symbol || taxType[tax_type]
                            : ""}
                          {(!userDetail.user.is_tax_exempted &&
                            !!tax && Number(tax)?.toFixed(2)) ||
                            0}
                          {tax_type == 1 ? taxType[tax_type] : ""}
                        </p>
                      </div> */}
                      <div className="checkout-cart-items">
                        <span>Total Tax</span>
                        <p>
                          {currency_symbol}{formatTaxAmount(calculation?.tax_amount)}
                        </p>
                      </div>

                      {calculation?.sale_order_level_taxes?.length ? calculation.sale_order_level_taxes.map(({ tax: { name }, value, amount }) =>
                        value ?
                          <div className="checkout-cart-items">
                            <span>{name}</span>
                            <td>
                              <div className="d-flex flex-column">
                                <p>
                                  {currency_symbol}{formatTaxAmount(amount)}
                                </p>
                              </div>
                            </td>
                          </div> : null
                      ) : null}
                      {calculation?.sale_order_li_total_taxes?.length ? calculation.sale_order_li_total_taxes.map(({ tax: { name }, amount }) =>
                        amount ?
                          <div className="checkout-cart-items">
                            <span>{name}</span>
                            <td >
                              <div className="d-flex flex-column">
                                <p>
                                  {currency_symbol}{formatTaxAmount(amount)}
                                </p>
                              </div>
                            </td>
                          </div> : null
                      ) : null}
                      <div className="checkout-cart-items invoice-total">
                        <span>Invoice Total</span>
                        <p>
                          {currency_symbol}{(formatCurrency(Number(saleTotal)))}
                        </p>
                      </div>
                    </>
                  )}
              </div>
              <div className="terms-policy">
                <label class="checkbox-container">
                  <div>
                    <input
                      type="checkbox"
                      name="accepted_terms"
                      checked={isSelected}
                      onChange={() => {
                        setIsSelected(!isSelected);
                        setFieldValue("accepted_terms", !isSelected);
                        setCheckoutShippingDetails((prevSaleDetail) => { return { ...prevSaleDetail, accepted_terms: !isSelected } })
                        _acceptedTerms = !isSelected;
                      }}
                    />
                    {/* To cross check temrs and condition to display checkbox here */}
                    {(activeTab === 2 || customPagesTermsValidation(customPages) || customPagesPrivacyPolicyValidation(customPages)) && <span className="checkmark"></span>}

                    {activeTab !== 2 && (
                      <>
                        {(customPagesTermsValidation(customPages) || customPagesPrivacyPolicyValidation(customPages)) && <span>
                          I Accept the {" "}
                          {customPagesTermsValidation(customPages) && <Link target="_blank"
                            className="term_condition-text"
                            to={customPagesTermsValidation(customPages).content.slug ? `/${convertToSlug(customPagesTermsValidation(customPages).content.slug)}` : `/${customPagesTermsValidation(customPages)?.slug}`}
                          >

                            <span className="term_condition-text">{TERMS_AND_CONDITION} </span>  </Link>}{customPagesPrivacyPolicyValidation(customPages) && <span className="term_condition-text">
                              <Link target="_blank"
                                className="term_condition-text"
                                to={customPagesPrivacyPolicyValidation(customPages).content.slug ? `/${convertToSlug(customPagesPrivacyPolicyValidation(customPages).content.slug)}` : `/${customPagesPrivacyPolicyValidation(customPages)?.slug}`}
                              >
                                {customPagesTermsValidation(customPages) && customPagesPrivacyPolicyValidation(customPages) && "& "} {PRIVACY_POLICY} </Link></span>}
                          {" "}
                        </span>}
                      </>

                    )}{activeTab === 2 && (
                      <span>
                        By Proceeding further, you acknowledge that
                        you have read the Terms and Conditions of the
                        Business owner of this E-Commerce Site
                        Kommerce Hub Platform and affiliated Payment
                        processors. If the payment is being made via
                        ACH, you confirm you authorize the Business
                        Owner to perform ACH debits or credits as per
                        this authorization that you are agreeing to
                        and also confirm that you have read the terms
                        of GrailPay who is the affiliated payment
                        processor of Kommerce Hub.{" "}
                      </span>
                    )}
                  </div>
                </label>
              </div>

              <div className="place-order mt-25">
                <Spin spinning={actionsLoading}>
                  <Button
                    style={{
                      ...buttonStyle(3, "100%", "18px"),
                      background: 'var(--style-primary-color, #B3B3B3)'
                    }}
                    label={
                      activeTab === 3
                        ? "Add card & Place Your Order"
                        : "Place Your Order"
                    }
                    onClick={(event) => {
                      if (
                        payment === paymentType.ACH_PAYMENT.value &&
                        !userDetail.user?.grailpay_uuid &&
                        !userDetail.user?.achHas &&
                        !bankInfo?.routing_number
                      ) {
                        addToast(
                          "Customer should be onboarded first",
                          {
                            appearance: "error",
                            autoDismissTimeout: 3000,
                            autoDismiss: true,
                          }
                        );
                        return;
                      }
                      if (!isSelected) {
                        const validationMessage = validationMessageForTermsAndConditionAndPrivacyPolicy(customPagesTermsValidation(customPages), customPagesPrivacyPolicyValidation(customPages))
                        addToast(
                          validationMessage,
                          {
                            appearance: "error",
                            autoDismissTimeout: 2000,
                            autoDismiss: true,
                          }
                        );
                        return;
                      }
                      if (
                        payment ===
                        paymentType.CREDIT_DEBIT_CARD.value
                      ) {
                        handleNMISetup(event, values, 5);
                        return;
                      }

                      if (
                        payment !==
                        paymentType.CREDIT_DEBIT_CARD.value
                      ) {
                        handleSubmit();
                      }
                    }}
                    disabled={disabled || actionsLoading}
                  // className="btn-hover"
                  />
                  {/* {activeTab === 3 &&
                   <Button
                    className="apple-pay-btn"
                    onClick={(event) => {
                      debugger
                        handleNMISetup(event, values, 7);
                    }}
                    disabled={disabled || actionsLoading}
                  >
                    <span  className="apple-pay-btn-label">
                      Pay with
                      <img alt="Apple" height="28px" width={"28px"} 
                        src={`${process.env.PUBLIC_URL}` + "/media/icons/apple-logo.png"}
                        className="apple-pay-img"
                      />
                       Pay & Place Your Order
                      </span>
                  </Button>
                 } */}
                </Spin>
                {/* <button
                            type="button"
                            onClick={() => {
                              if (
                                payment === paymentType.ACH_PAYMENT.value &&
                                !userDetail.user?.grailpay_uuid &&
                                !userDetail.user?.achHas
                              ) {
                                addToast("Customer should be onboarded first", {
                                  appearance: "error",
                                  autoDismissTimeout: 3000,
                                  autoDismiss: true,
                                });
                                return;
                              }
                              if (!isSelected) {
                                addToast(
                                  "Please accept the Terms and Conditions.",
                                  {
                                    appearance: "error",
                                    autoDismissTimeout: 2000,
                                    autoDismiss: true,
                                  }
                                );
                                return;
                              }
                              handleSubmit();
                            }}
                            disabled={disabled}
                            className="btn-hover"
                          >
                            Place Order
                          </button> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </Formik>
  )
}
CartFinal.propTypes = {
  cartItems: PropTypes.array,
  settings: PropTypes.object,
  sale: PropTypes.object,
  userDetail: PropTypes.object,
  deleteFromCart: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    cartItems: state.cartData,
    settings: state.settingData.ecom.basic,
    sale: state.saleData,
    userDetail: state.userData,
    adminSettings: state.settingData?.["admin-panel"] || { basic: { currency_symbol: "$" } },
  };
};


export default connect(mapStateToProps)(CartFinal);