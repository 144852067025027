import {
  SALE_ORDER_SUCCESS,
  FETCH_STATES_SUCCESS,
  FETCH_CITIES_SUCCESS,
  FETCH_ALL_ORDER_SUCCESS,
  FETCH_SINGLE_ORDER_SUCCESS,
  FETCH_LOADING_SUCCESS,
  FETCH_FORM_DATA,
  FETCH_BILLING_STATES_SUCCESS,
  FETCH_BILLING_CITIES_SUCCESS,
  SET_FORM_DATA,
} from "../constant_types";

const initState = {
  orderEntities: [],
  singleOrder: {},
  states: [],
  cities: [],
  billingStates: [],
  billingCities: [],
  totalCount: null,
  pageSize: null,
  loading: false,
  formData: {},
  message: {
    success: null,
    error: null,
  },
};

const saleReducer = (state = initState, action) => {
  if (action.type === FETCH_ALL_ORDER_SUCCESS) {
    return {
      ...state,
      orderEntities: action.payload.entities,
      pageSize: action.payload.pageSize,
      totalCount: action.payload.totalCount,
    };
  }
  if (action.type === FETCH_SINGLE_ORDER_SUCCESS) {
    return { ...state, singleOrder: action.payload };
  }
  if (action.type === FETCH_FORM_DATA) {
    return { ...state, ...action.payload };
  }
  if (action.type === SALE_ORDER_SUCCESS) {
    return {
      ...state,
      message: {
        success: action.payload.message,
      },
      singleOrder: action.payload.entity,
    };
  }
  if (action.type === FETCH_STATES_SUCCESS) {
    return {
      ...state,
      states: action.payload,
    };
  }

  if (action.type === FETCH_BILLING_STATES_SUCCESS) {
    return {
      ...state,
      billingStates: action.payload,
    };
  }

  if (action.type === FETCH_CITIES_SUCCESS) {
    return {
      ...state,
      cities: action.payload,
    };
  }

  if (action.type === FETCH_BILLING_CITIES_SUCCESS) {
    return {
      ...state,
      billingCities: action.payload,
    };
  }

  if (action.type === FETCH_LOADING_SUCCESS) {
    return {
      ...state,
      loading: action.payload,
    };
  }

  if (action.type === SET_FORM_DATA) {
    return {
      ...state,
      formData: { ...action.payload },
    };
  }

  return state;
};

export default saleReducer;
